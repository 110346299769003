import React,{useEffect} from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import DashboardImage from '../../src/images/dashboard.png'
import ListImage from '../../src/images/list.png'
import CreateImage from '../../src/images/create.png'
import userIcon from '../../src/images/add-user.png'
import Group from '../../src/images/group.png'
import Layer from '../../src/images/layers.png'
import Brandimg from '../../src/images/brands.png'
import ProductsImage from '../../src/images/products.png'
import ModelImage from '../../src/images/model.png'
import { IoReturnDownBack } from "react-icons/io5";
import { Link } from 'react-router-dom'

const Sidebar = () => {
    const navigate = useNavigate()
    const logout = ()=>{
        localStorage.removeItem('token')
        navigate('/')
    }
    
  return (
    <div className='sidebar'>
        <div className="mb-3">
        <Link to='/dashboard' className="back">
        <IoReturnDownBack  /> Back to dashboard
    </Link>
        </div>
        <ul>
            <li>
                <NavLink to='/admin'>
                <img src={DashboardImage}  />  Dashboard
                </NavLink>
              
            </li>
            <li>
                
                <NavLink to='/users'>
                <img src={ CreateImage}  />    Users
                </NavLink>
            </li>
            <li>
                
                <NavLink to='/roles'>
                <img src={ CreateImage}  />    Roles
                </NavLink>
            </li>
            <li>
                
                <NavLink to='/permissions'>
                <img src={ CreateImage}  />    Permissions
                </NavLink>
            </li>
           
            <li >
           <div className="logout" style={{cursor:'pointer'}} onClick={()=>logout()}>
           <i class="fa-solid fa-arrow-right-from-bracket"></i> <span >Logout</span>
           </div>
            </li>
            
        </ul>
    </div>
  )
}

export default Sidebar