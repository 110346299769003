import React, { useEffect, useState } from "react";
import { Services } from "../../api/Services";
import { ToastContainer, toast } from "react-toastify";
import { IoReturnDownBack } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import User from "../../images/user.png";
import Sidebar from "../Sidebar";

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);
 
  const getUserDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getUserDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setUser(result?.data?.data?.user);
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getUserDetailFunction(id);
  }, []);
  return (
    <div className=' dark-theme py-3'>
   
      <ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="main-dashboard">
        <Sidebar />
        <div className="right">
        <div className="row">
          
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
              {user?.companies?.length > 0 && (
                <h2 className="title-main">Compnies</h2>
              )}
              <div className="row">
                {user?.companies?.map((comp) => (
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="user-wrapper pointer" onClick={()=> navigate(`/company-detail/${comp.id}`)} >
                     
                    
                      <div className="data">
                        {comp?.company_name && <p className="name"> <i class="fa-solid fa-user-tie"></i> Company name: {comp?.company_name}</p>}
                        {comp?.email && <p className="name"><i class="fa-regular fa-envelope"></i>Company email: {comp?.email}</p>}
                        {comp?.mobile_number && <p className="name"><i class="fa-solid fa-mobile-retro"></i>Company mobile #: {comp?.mobile_number}</p>}
                        
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <h2 className="title-main">User details</h2>
              <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                  
                    {user?.photo ? <img src={`https://account.himalayatool.com/storage/uploads/${user?.photo}`} /> : <img src={User} />}
                  </div>
                </div>
                {user?.picture_cnic_back && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                  
                    {user?.photo ? <img src={`https://account.himalayatool.com/storage/uploads/${user?.picture_cnic_back}`} /> : <img src={User} />}
                  </div>
                </div>}
                {user?.picture_cnic_front && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                  
                    {user?.photo ? <img src={`https://account.himalayatool.com/storage/uploads/${user?.picture_cnic_front}`} /> : <img src={User} />}
                  </div>
                </div>}
                
              
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">User name</p>
                    <p className="value">{user?.name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">First name</p>
                    <p className="value">{user?.first_name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Last name</p>
                    <p className="value">{user?.last_name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Email</p>
                    <p className="value">{user?.email}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Username</p>
                    <p className="value">{user?.username}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">So/Do</p>
                    <p className="value">{user?.so_do}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Date of birth</p>
                    <p className="value">{user?.date_of_birth}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">CNIC number</p>
                    <p className="value">{user?.cnic_number}</p>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Martial status</p>
                    <p className="value">{user?.marital_status}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Home address</p>
                    <p className="value">{user?.home_address}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Mobile number</p>
                    <p className="value">{user?.mobile_number}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Office phone</p>
                    <p className="value">{user?.emergency_contact_number}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Department</p>
                    <p className="value">
                      {user?.derpartment}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Designation</p>
                    <p className="value">{user?.designation}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">User type</p>
                    <p className="value">{user?.user_type}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Starting salary</p>
                    <p className="value">{user?.starting_salary}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
