import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import userAvatar from '../../images/user.png'

const ProductsList = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [products,setProducts]=useState([])
   
const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[])
useEffect(()=>{
    getProductsListFunction()
},[])
    const getProductsListFunction = async ()=> {
        
            setIsLoading(true);
            const result = await Services.getProductsList()
            console.log(result)
            if (result?.status === 200) {
            
           setProducts(result?.data?.data?.products_to_list)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    //delete company api
    const deleteProduct = async (id) => {
        const isDelete = window.prompt('Do you want to delet product from list?');

        if (isDelete !== null) {
            setIsLoading(true);
            const result = await Services.deleteProuctFromList(id);
            
            if (result?.status === 200) {
                toast.success("product deleted from list", {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
                  getProductsListFunction()
            } else {
              toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setIsLoading(false);
        } 
        
      };

  return (
    <div className='create-company dark-theme py-3'>
    <ProductBar />
<Link to='/products' className="back">
    <IoReturnDownBack  /> Go to products
</Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="container-fluid">
    <div className="row">
     
        <div className="col-12 col-md-12">
     <div className="container pt-4">
        <div className="compnies-list">
        <div className="table-responsive">
            <table className='table'>
                <thead>
                <th>Id</th>
                    <th>Product Name</th>
                    <th>Brand name</th>
                    <th>Product description</th>
                    <th>Model number</th>
                    
                    
                    <th>Action</th>
                </thead>
                <tbody>
                {products?.filter(product => product?.is_publish == '1')?.map((product, id) => (
  <tr key={id}>
    <td>{product?.products?.id}</td>
    <td>{product?.products?.name}</td>
    <td>{product?.brands?.name}</td>
    <td>
      <div
        className="value"
        dangerouslySetInnerHTML={{ __html: product?.description }}
      ></div>
    </td>
    <td>{product?.model_id}</td>
    <td>
      <div className="actions">
        {/* Conditionally render the "Add in inventory" button */}
        {!product?.inventories && (
          <button
            className="button button-small"
            onClick={() => navigate(`/add-inventory/${product?.id}`)}
          >
            Add in inventory
          </button>
        )}
        <i
          className="fa-solid fa-eye"
          title="View product"
          onClick={() => navigate(`/products-list-detail/${product?.id}`)}
        ></i>
        <i
          className="fa-solid fa-pencil"
          title="Edit product"
          onClick={() => navigate(`/update-product-to-list/${product?.id}`)}
        ></i>
        <i
          className="fa-solid fa-trash-can"
          title="Delete product"
          onClick={() => deleteProduct(product?.id)}
        ></i>
      </div>
    </td>
  </tr>
))}


                </tbody>
            </table>
        </div>
        </div>
        {products?.length === 0 && <h4 className='text-center title_medium'>No record found</h4>}
        
     </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default ProductsList