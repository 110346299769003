
import React, {useState,useEffect} from 'react'

import { Services } from '../../api/Services';
import { ToastContainer, toast } from "react-toastify";
import { IoReturnDownBack } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import userAvatar from "../../images/user.png";
const BrandDetail = () => {
    const navigate =useNavigate()
    const token = localStorage.getItem("token");
    useEffect(() => {
      if (!token) {
        navigate("/");
      }
    }, []);
    const { id } = useParams();
    const [loading, setIsLoading] = useState(false);
    const [brand, setBrand] = useState({});
    const getBrandApi = async (id) => {
        setIsLoading(true);
        const result = await Services.getBrandDetail(id);
        console.log(result);
        if (result?.status === 200) {
          setBrand(result?.data?.data);
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
      };
      useEffect(() => {
        getBrandApi(id);
      }, [id]);
  return (
    <div className='create-company dark-theme py-3'>
    
<Link to='/brands' className="back">
    <IoReturnDownBack  /> Go to brands list
</Link>
<ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
          <div className="row">
       
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Brand details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Brand name</p>
                    <p className="value">{brand?.name}</p>
                  </div>
                </div>
               
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Brand Origin</p>
                    <p className="value">{brand?.origin}</p>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Brand Category</p>
                    {/* <p className="value">{brand?.categories[0]?.name}</p> */}
                    <ul className='brand-categories'>
                      {brand?.categories?.map((cat,id)=><li>{cat?.name}</li>)}
                      
                    </ul>
                  </div>
                </div>
                
              
               
               
              
                
               
              </div>

           
        
            </div>
          </div>
        </div>  
      </div>
</div>
  )
}

export default BrandDetail