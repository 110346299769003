import React from 'react'
import Sidebar from '../Sidebar'
const Dashboard = () => {
  return (
    <div className='main-dashboard dark-theme'>
      <Sidebar/ >
      <div className="right">
        test
      </div>
    </div>
  )
}

export default Dashboard