import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import userAvatar from '../../images/user.png'
import Sidebar from '../Sidebar';
import { CustomerServices } from '../../api/CustomerService';
import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
const Vendors = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    
    const [vendors,setVendors]=useState([])
   
const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[])
useEffect(()=>{
    getVendorsList()
},[])
    const getVendorsList = async ()=> {
        
            setIsLoading(true);
            const result = await CustomerServices.getVendors()
            console.log(result)
            if (result?.status === 200) {
            
           setVendors(result?.data?.data?.vendors)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    //delete vendor
    const deleteVendorFunction = async (id) => {
        const isDelete = window.prompt('Do you want to delet BModel from list?');

        if (isDelete !== null) {
            setIsLoading(true);
            const result = await CustomerServices.deleteVendor(id);
            
            if (result?.status === 200) {
                toast.success("Model deleted from list", {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
                  getVendorsList()
            } else {
              toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setIsLoading(false);
        } 
        
      };

  return (
    <div className='create-company dark-theme py-3'>
   
<Link to='/dashboard' className="back">
    <IoReturnDownBack  /> Go to Dashboard
</Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
     <div className="container pt-4">
        <div className="compnies-list">
        <div className="d-flex justify-content-end mb-4">
        <Link className='button' to="/create-vendor">Creat Vendor</Link>
    </div>
        <div className="table-responsive">
            <table className='table'>
                <thead>
              
                    <th>Name</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Head Office Phone</th>
                    
                    
                   
                    <th>Action</th>
                </thead>
                <tbody>
                    {vendors?.map((vendor)=> <tr key={vendor?.id}>
                   
                        <td>{vendor?.name}  </td>
                        <td>{vendor?.address}  </td>
                        <td>{vendor?.phone_1}  </td>
                        <td>{vendor?.head_office_address}  </td>
                        
                         
                        <td><div className="actions">
                        <i class="fa-regular fa-eye" title='View Model' onClick={()=>navigate(`/vendor-detail/${vendor?.id}`)}></i>
                        
                        <i class="fa-regular fa-pen-to-square" title='Update Model' onClick={()=>navigate(`/update-vendor/${vendor?.id}`)}></i>
                       
                        <i class="fa-solid fa-trash-can" title='Delete Model' onClick={()=>deleteVendorFunction(vendor?.id)}> </i>
                            </div></td>
                    </tr>)}
               
                </tbody>
            </table>
        </div>
        </div>
        {vendors?.length === 0 && <h4 className='text-center title_medium'>No record found</h4>}
        
     </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default Vendors