import React,{useEffect, useState} from 'react'
import TaxesBar from './TaxesBar'
import { Link } from 'react-router-dom'
import { IoReturnDownBack } from "react-icons/io5";
import { Services } from '../../api/Services';
import { CiEdit } from "react-icons/ci";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const WithholdingTaxes = () => {
    const [loading,setIsLoading]=useState(false)
    const [activeTab,setActiveTab]=useState('income')
    const [ratioIncome,setRatioIncome]=useState('')
    const [applicableDateIncome,setApplicableDateIncome]=useState('')
    const [incomeRead,setIncomeRead]=useState(true)
    const [incomeTaxDetail,setIncomeTaxDetail]=useState({})
    const [ratioSale,setRatioSale]=useState('')
    const [applicableDateSale,setApplicableDateSale]=useState('')
    const [salesRead,setSalesRead]=useState(true)


    const incomeTaxDetailApi = async ()=> {
     
      
        
            setIsLoading(true)
       
    
           
          
            const result = await Services.getWithHoldingTaxIcomeDetail()
            console.log(result)
            if (result?.status === 200) {
           setRatioIncome(result?.data?.data?.with_holding_taxes_ratios[0].tax_ratio)
           setApplicableDateIncome(result?.data?.data?.with_holding_taxes_ratios[0].applied_from)
           console.log(result)
           
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        
    }

    useEffect(()=>{
        incomeTaxDetailApi()
    },[])
     //api call to create income tax

     const withHoldingIncomeTaxCreate = async ()=> {
        if(ratioIncome==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            setIsLoading(true)
            const formData = new FormData();
            if(ratioIncome){
                formData.append("tax_ratio",ratioIncome)
            }
            if(ratioIncome){
                formData.append("type",'income_tax')
            }
            if(applicableDateIncome){
                formData.append("applied_from",applicableDateIncome)
            }
    
           
          
            const result = await Services.withHoldingTaxIcome(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success(result?.data?.message, {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            setRatioIncome('');
            setApplicableDateIncome('')
            incomeTaxDetailApi()
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }


    //sales api calls
    const salesTaxDetailApi = async ()=> {
     
      
        
        setIsLoading(true)
   

       
      
        const result = await Services.getWithHoldingTaxSalesDetail()
        console.log(result)
        if (result?.status === 200) {
       setRatioSale(result?.data?.data?.with_holding_taxes_ratios[0].tax_ratio)
       setApplicableDateSale(result?.data?.data?.with_holding_taxes_ratios[0].applied_from)
       console.log(result)
       
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    
}

useEffect(()=>{
    salesTaxDetailApi()
},[])
 //api call to create sales tax

 const withHoldingSalesTaxCreate = async ()=> {
    if(ratioSale==""){
        toast.error("please enter ratio", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        setIsLoading(true)
        const formData = new FormData();
        if(ratioSale){
            formData.append("tax_ratio",ratioSale)
        }
        if(ratioSale){
            formData.append("type",'sales_tax')
        }
        if(applicableDateSale){
            formData.append("applied_from",applicableDateSale)
        }

       
      
        const result = await Services.withHoldingTaxSales(formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success(result?.data?.message, {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        setRatioSale('');
        setApplicableDateSale('')
        salesTaxDetailApi()
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}




  return (
    <div className='create-company dark-theme py-3'>
    <TaxesBar />
<Link to='/dashboard' className="back">
    <IoReturnDownBack  /> Go to dashboard
</Link>
<ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="taxes-wrapper">
    <div className="tabs">
        <div className="tab">
            <button className={`button ${activeTab == 'income' ? 'active' : ''}`} onClick={()=>setActiveTab('income')}>
                INCOME TAX WITHHELD
            </button>
        </div>
        <div className="tab">
        <button className={`button ${activeTab == 'sales' ? 'active' : ''}`} onClick={()=>setActiveTab('sales')}>
                SALES TAX WITHHELD
            </button>
        </div>
    </div>

    {activeTab == 'income' &&     <div className="form-wrapper-tax form-wrapper ">
         <h2 className="title-small text-center">Income Tax withheld</h2>
         <div className="row">
            <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Ratio
                    </span>
                    <span className="edit" onClick={()=>setIncomeRead((pre)=>!pre)}>
                    <CiEdit /> <span>Edit</span>
                    </span>
                </div>
                <input type="text" className='input' readOnly={incomeRead} value={ratioIncome} placeholder='Tax ratio' onChange={(e)=>setRatioIncome(e.target.value)}  />
                </div>
            </div>
             <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Applicable Date
                    </span>
                    
                </div>
                <input type="date" className='input' readOnly={incomeRead} value={applicableDateIncome} placeholder='Tax ratio' onChange={(e)=>setApplicableDateIncome(e.target.value)}  />
                </div>
            </div>
            </div>
            <div className="d-flex justify-content-center my-4">
                 <button className="button" onClick={withHoldingIncomeTaxCreate}>Save Now</button>
            </div>
         
         </div>}

         {/* ====================== Sales tax goes here ================== */}

         {activeTab == 'sales' &&     <div className="form-wrapper-tax form-wrapper ">
         <h2 className="title-small text-center">Sales Tax withheld</h2>
         <div className="row">
            <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Ratio
                    </span>
                    <span className="edit" onClick={()=>setSalesRead((pre)=>!pre)}>
                    <CiEdit /> <span>Edit</span>
                    </span>
                </div>
                <input type="text" className='input' readOnly={salesRead} value={ratioSale} placeholder='Tax ratio' onChange={(e)=>setRatioSale(e.target.value)}  />
                </div>
            </div>
             <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Applicable Date
                    </span>
                    
                </div>
                <input type="date" className='input' readOnly={salesRead} value={applicableDateSale} placeholder='Tax ratio' onChange={(e)=>setApplicableDateSale(e.target.value)}  />
                </div>
            </div>
            </div>
            <div className="d-flex justify-content-center my-4">
               <button className="button" onClick={withHoldingSalesTaxCreate}>Save Now</button>
            </div>
         
         </div>}
</div>
</div>
  )
}

export default WithholdingTaxes