import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import Sidebar from '../Sidebar';
const Users = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [users,setUsers]=useState([])
   
const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[])
useEffect(()=>{
    getUsersList()
},[])

//delete user api
const deleteUserFunction = async (id) => {
    const isDelete = window.prompt('Do you want to delet user from list?');

    if (isDelete !== null) {
        setIsLoading(true);
        const result = await Services.deleteUser(id);
        
        if (result?.status === 200) {
            toast.success("User deleted from list", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              getUsersList()
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
    } 
    
  };
    const getUsersList = async ()=> {
        
            setIsLoading(true);
            const result = await Services.getUsers()
            console.log(result)
            if (result?.status === 200) {
            
           setUsers(result?.data?.data?.users)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    

  return (
    <div className='create-company dark-theme py-3'>
        
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="main-dashboard">
<Sidebar />
<div className="right">
<div className="row">
        
        <div className="col-12 col-md-12">
     <div className="container pt-4">
        <div className="compnies-list">
            <div className="d-flex justify-content-end">
                <Link to='/add-user' className="button">Create new user</Link>
            </div>
        <div className="table-responsive">
            <table className='table'>
                <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                  
                    <th>CNIC</th>
                    <th>Mobile</th>
                    <th>Starting Salary</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    {users?.map((user,id)=> <tr key={id}>
                        <td>{user?.name}</td>
                        <td>{user?.email}</td>
                        <td>{user?.first_name}</td>
                        <td>{user?.last_name}</td>
                        <td>{user?.cnic_number}</td>
                        <td>{user?.mobile_number}</td>
                        <td>{user?.starting_salary}</td>
                        <td>
                            <div className="actions">
                        <span onClick={()=>navigate(`/user-detail/${user?.id}`)}> <GrView /> </span>
                        <span onClick={()=>navigate(`/update-user/${user?.id}`)}>< CiEdit /></span>
                        <span onClick={()=>deleteUserFunction(user?.id)}> <MdDeleteOutline /> </span>
                        
                        
                       
                      
                            </div></td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        {users?.length === 0 && <h1 className='text-center title_medium'>No record found</h1>}
        
     </div>
        </div>
    </div>
</div>
  
</div>

    </div>
  )
}

export default Users