import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import userAvatar from '../../images/user.png'
import { CiEdit } from "react-icons/ci";
import { IoReturnDownBack } from "react-icons/io5";

import { MdOutlineClose } from "react-icons/md";
import { CustomerServices } from '../../api/CustomerService';

const CreateCustomer = () => {
    const navigate = useNavigate()

    const [modal,setModal]=useState(false)
    const [groups,setGroups]=useState([]);
    const [gname,setGName] = useState('');
    const [loading,setIsLoading]=useState(false)
      const [tab,setTab]=useState('terms')
    const [name,setName]=useState('')
    const [customerCategory,setCustomerCategory]=useState('')
    const [groupId,setGroupId]=useState('')
    const [postalAddress,setPostalAddress]=useState('')

    const [companyAddress,setCompanyAddress]=useState('')
    const [headOfficeAddress,setHeadOfficeAddress]=useState('')
    const [phone1,setPhone1]=useState('')
    const [phone2,setPhone2]=useState('')
    const [phone3,setPhone3]=useState('')
    const [headOfficePhone1,setHeadOfficePhone1]=useState('')
    const [headOfficePhone2,setHeadOfficePhone2]=useState('')
    const [headOfficePhone3,setHeadOfficePhone3]=useState('')
    const [isRegistered,setIsRegistered]=useState('')
    const [isNTN,setIsNTN]=useState('')
    const [NTNNumber,setNTNNumber]=useState('')
    const [isFTN,setIsFTN]=useState('')
    const [FTNNumber,setFTNNumber]=useState('')
    const [isSalexTaxReGistered,setIsSalexTaxReGistered]=useState('')
    const [salexTaxNumber,setSalesTaxNumber]=useState('')
    // busines tersm states
    const [debitDays,setDebitDays]=useState('')
    
    const [debitBussinessAmountLimit,setdebitBussinessAmountLimit]=useState('')
    const [debitOverdueAmountLimit,setdebitOverdueAmountLimit]=useState('')
    const [debitSalesAmountLimit,setdebitSalesAmountLimit]=useState('')
   //tax states
   const [incomeTaxWithHeldType,setIncomeTaxWithHeldType] = useState('')
   const [incomeTaxWithHeld,setIncomeTaxWithHeld] = useState('')
   const [incomeTaxWithHeldDate,setIncomeTaxWithHeldDate] = useState('')
  
   const [manualIncomeTaxWithHeld,setMannualIncomeTaxWithHeld] =useState('')
   const [manualIncomeTaxWithHeldDate,setMannualIncomeTaxWithHeldDate] =useState('')
   const [salesWithHeldType,setSalesWithHeldType] = useState('')
   const [salesWithHeld,setSalesWithHeld] = useState('')
   const [salesWithHeldDate,setSalesWithHeldDate] = useState('')
   const [manualSalesWithHeldTax,setMannualSalesWithHeldTax] =useState('')
   const [manualSalesWithHeldDate,setMannualSalesWithHeldDate] =useState('')



//    contact states goese here
//============purchase person info ===============//
const [purchasePersons,setPurchasePersons] = useState([{
    name:'',
    designation:'',
    mobile:'',
    phone : '',
    fax:'',
    email:''
}])
const purchasePayLoad =  purchasePersons?.map(item => {
    return {
        name: item?.name,  
        designation: item?.designation,  
        mobile: item?.mobile,  
        phone: item?.phone,  
        fax: item?.fax,  
        email: item.email,
        type:'purchase_person' ,
    };
});
//handle purchase persons

const handlePurchase = (index,name,value)=>{
    const prevValues = [...purchasePersons]
    prevValues[index][name]=value
    setPurchasePersons(prevValues)
}

//add purchase person
const addPurchasePerson =()=>{
    const latestValues = [...purchasePersons,{
        name:'',
        designation:'',
        mobile:'',
        phone : '',
        fax:'',
        email:''
    }]
    setPurchasePersons(latestValues)
}
//remove purchase person
const removePurchasePerson =(index)=>{
    const latestValues = purchasePersons?.filter((_,i)=> i != index)
    setPurchasePersons(latestValues)
}

//============Store person info ===============//
const [storePersons,setStorePersons] = useState([{
    name:'',
    designation:'',
    mobile:'',
    phone : '',
    fax:'',
    email:''
}])
const storePayLoad =  storePersons?.map(item => {
    return {
        name: item?.name,  
        designation: item?.designation,  
        mobile: item?.mobile,  
        phone: item?.phone,  
        fax: item?.fax,  
        email: item.email,
        type:'store_person' ,
    };
});
//handle store persons

const handleStore = (index,name,value)=>{
    const prevValues = [...storePersons]
    prevValues[index][name]=value
    setStorePersons(prevValues)
}

//add store person
const addStorePerson =()=>{
    const latestValues = [...storePersons,{
        name:'',
        designation:'',
        mobile:'',
        phone : '',
        fax:'',
        email:''
    }]
    setStorePersons(latestValues)
}
//remove store person
const removeStorePerson =(index)=>{
    const latestValues = storePersons?.filter((_,i)=> i != index)
    setStorePersons(latestValues)
}



//============Invoice person info ===============//
const [invoicePersons,setInvoicePersons] = useState([{
    name:'',
    designation:'',
    mobile:'',
    phone : '',
    fax:'',
    email:''
}])

const invoicePayLoad =  invoicePersons?.map(item => {
    return {
        name: item?.name,  
        designation: item?.designation,  
        mobile: item?.mobile,  
        phone: item?.phone,  
        fax: item?.fax,  
        email: item.email,
        type:'invoice_person' ,
    };
});
//handle invoice persons

const handleInvoice = (index,name,value)=>{
    const prevValues = [...invoicePersons]
    prevValues[index][name]=value
    setInvoicePersons(prevValues)
}

//add invoice person
const addInvoicePerson =()=>{
    const latestValues = [...invoicePersons,{
        name:'',
        designation:'',
        mobile:'',
        phone : '',
        fax:'',
        email:''
    }]
    setInvoicePersons(latestValues)
}
//remove invoice person
const removeInvoicePerson =(index)=>{
    const latestValues = invoicePersons?.filter((_,i)=> i != index)
    setInvoicePersons(latestValues)
}



//============Ledgers person info ===============//
const [ledgersPersons,setLedgersPersons] = useState([{
    name:'',
    designation:'',
    mobile:'',
    phone : '',
    fax:'',
    email:''
}])
const ledgerPayLoad =  ledgersPersons?.map(item => {
    return {
        name: item?.name,  
        designation: item?.designation,  
        mobile: item?.mobile,  
        phone: item?.phone,  
        fax: item?.fax,  
        email: item.email,
        type:'ledgers_person' ,
    };
});
//handle Ledgers persons

const handleLedgers = (index,name,value)=>{
    const prevValues = [...ledgersPersons]
    prevValues[index][name]=value
    setLedgersPersons(prevValues)
}

//add Ledgers person
const addLedgersPerson =()=>{
    const latestValues = [...ledgersPersons,{
        name:'',
        designation:'',
        mobile:'',
        phone : '',
        fax:'',
        email:''
    }]
    setLedgersPersons(latestValues)
}
//remove Ledgers person
const removeLedgersPerson =(index)=>{
    const latestValues = ledgersPersons?.filter((_,i)=> i != index)
    setLedgersPersons(latestValues)
}


//============Account person info ===============//
const [accountPersons,setAccountPersons] = useState([{
    name:'',
    designation:'',
    mobile:'',
    phone : '',
    fax:'',
    email:''
}])
const accountPayLoad =  accountPersons?.map(item => {
    return {
        name: item?.name,  
        designation: item?.designation,  
        mobile: item?.mobile,  
        phone: item?.phone,  
        fax: item?.fax,  
        email: item.email,
        type:'account_person' ,
    };
});
//handle Account persons

const handleAccount = (index,name,value)=>{
    const prevValues = [...accountPersons]
    prevValues[index][name]=value
    setAccountPersons(prevValues)
}

//add Account person
const addAccountPerson =()=>{
    const latestValues = [...accountPersons,{
        name:'',
        designation:'',
        mobile:'',
        phone : '',
        fax:'',
        email:''
    }]
    setAccountPersons(latestValues)
}
//remove Accoun person
const removeAccountPerson =(index)=>{
    const latestValues = accountPersons?.filter((_,i)=> i != index)
    setAccountPersons(latestValues)
}

//get taxes apis functions
//income tax api call
const incomeTaxDetailApi = async ()=> {
     
      
        
    setIsLoading(true)


   
  
    const result = await Services.getWithHoldingTaxIcomeDetail()
    console.log(result)
    if (result?.status === 200) {
   setIncomeTaxWithHeld(result?.data?.data?.with_holding_taxes_ratios[0].tax_ratio)
   setIncomeTaxWithHeldDate(result?.data?.data?.with_holding_taxes_ratios[0].applied_from)
   console.log(result)
   
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);

}

useEffect(()=>{
incomeTaxDetailApi()
},[])
 
      //sales api calls
      const salesTaxDetailApi = async ()=> {
     
      
        
        setIsLoading(true)
   

       
      
        const result = await Services.getWithHoldingTaxSalesDetail()
        console.log(result)
        if (result?.status === 200) {
       setSalesWithHeld(result?.data?.data?.with_holding_taxes_ratios[0].tax_ratio)
       setSalesWithHeldDate(result?.data?.data?.with_holding_taxes_ratios[0].applied_from)
       console.log(result)
       
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    
}

useEffect(()=>{
    salesTaxDetailApi()
},[])


const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])

//create Group section
const addGroupApi = async ()=> {
    if(gname==""){
        toast.error("please enter name", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        const formData = new FormData();
        if(gname){
            formData.append("name",gname)
        }
      
  
       
        setIsLoading(true);
        const result = await CustomerServices.addGroup(formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success("Group added sucessfuly", {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          getGroupList()
          setModal(false)
         
          setGName('')
         
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}


//get group list

    const getGroupList = async ()=> {
        
            setIsLoading(true);
            const result = await CustomerServices.getGroups()
            console.log(result)
            if (result?.status === 200) {
            
           setGroups(result?.data?.data?.groups)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }

        useEffect(()=>{
            getGroupList()
        },[])


   



 
  



 


 
    const addCustomer = async ()=> {
        if(name==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
      
        else {
            const formData = new FormData();
            if(name){
                formData.append("name",name)
            }
            if(customerCategory){
                formData.append("customer_category",customerCategory)
            }
            if(groupId){
                formData.append("group_id",groupId)
            }
            if(companyAddress){
                formData.append("company_address",companyAddress)
            }
            if(postalAddress){
                formData.append("postal_address",postalAddress)
            }
            if(headOfficeAddress){
                formData.append("head_office_address",headOfficeAddress)
            }
           
            if(phone1){
                formData.append("factory_phone_1",phone1)
            }
            if(phone2){
                formData.append("factory_phone_2",phone2)
            }
            if(phone3){
                formData.append("factory_phone_3",phone3)
            }

            if(headOfficePhone1){
                formData.append("head_office_phone_1",headOfficePhone1)
            }
            if(headOfficePhone2){
                formData.append("head_office_phone_2",headOfficePhone2)
            }
            if(headOfficePhone3){
                formData.append("head_office_phone_3",headOfficePhone3)
            }
            if(isRegistered) {
                
                formData.append("vendor_type",isRegistered)
            }

            if(isSalexTaxReGistered){
                formData.append('vendor_sales_tax_register',isSalexTaxReGistered)
               }
               if(isFTN){
                formData.append('register_in_ftn',isFTN)
               }
               
               if(isNTN){
                formData.append('register_in_ntn',isNTN)
               }
               if(incomeTaxWithHeldType){
                formData.append("income_tax_with_held",incomeTaxWithHeldType)
               }
               if(salesWithHeldType){
                formData.append("sales_tax_with_held",salesWithHeldType)
               }

            if(NTNNumber){
                formData.append("ntn_number",NTNNumber)
            }

            
            if(FTNNumber){
                formData.append("ftn_number",FTNNumber)
            }
            if(salexTaxNumber){
                formData.append("sales_tax_number",salexTaxNumber)
            }



            if(debitDays){
                formData.append("debit_days",debitDays)
            }
            if(debitBussinessAmountLimit){
                formData.append("debit_business_amount_limit",debitBussinessAmountLimit)
            }
            if(debitOverdueAmountLimit){
                formData.append("debit_overdue_amount_limit",debitOverdueAmountLimit)
            }
            if(debitSalesAmountLimit){
                formData.append("debit_sales_amount_limit",debitSalesAmountLimit)
            }
           

            if((incomeTaxWithHeldType == 'tax' && (isRegistered == 'tax_register') && (isSalexTaxReGistered == '1') )){
                if(incomeTaxWithHeld){
                    formData.append("income_tax_with_held_ratio",incomeTaxWithHeld)
                }
                if(incomeTaxWithHeldDate){
                    formData.append("income_tax_with_held_applicable_date",incomeTaxWithHeldDate)
                }
             }
             if(incomeTaxWithHeldType == 'manual'){
                if(manualIncomeTaxWithHeld){
                    formData.append("income_tax_with_held_ratio",manualIncomeTaxWithHeld)
                }
                if(manualIncomeTaxWithHeldDate){
                    formData.append("income_tax_with_held_applicable_date",manualIncomeTaxWithHeldDate)
                }
             }



             if((salesWithHeldType == 'tax' && (isRegistered == 'tax_register') && (isSalexTaxReGistered == '1') )){
                if(salesWithHeld){
                    formData.append("sales_tax_with_held_ratio",salesWithHeld)
                }
                if(salesWithHeldDate){
                    formData.append("sales_tax_with_held_applicable_date",salesWithHeldDate)
                }
             }
             if(salesWithHeldType == 'manual'){
                if(manualSalesWithHeldTax){
                    formData.append("sales_tax_with_held_ratio",manualSalesWithHeldTax)
                }
                if(manualSalesWithHeldDate){
                    formData.append("sales_tax_with_held_applicable_date",manualSalesWithHeldDate)
                }
             }
        
          if(purchasePersons){
            formData.append("purchase_persons",JSON.stringify(purchasePayLoad))
          }
          if(storePersons){
            formData.append("store_persons",JSON.stringify(storePayLoad))
          }
          if(invoicePersons){
            formData.append("invoice_persons",JSON.stringify(invoicePayLoad))
          }
          if(ledgersPersons){
            formData.append("ledgers_persons",JSON.stringify(ledgerPayLoad))
          }
          if(accountPersons){
            formData.append("account_persons",JSON.stringify(accountPayLoad))
          }
          

      
           
          
        
            
          
          
           
           
            setIsLoading(true);
            const result = await CustomerServices.addCustomer(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Customer added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/customers')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    


  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/customers' className="back">
        <IoReturnDownBack  /> Go to customers list
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
    {modal && <div className='modal-fixed'>
        <div className="form-modal">
            <div className="icon">
            <i class="fa-regular fa-circle-xmark" onClick={()=>setModal(false)}></i>
            </div>
            <p className="title">
                Create Group
            </p>

            <div className="form-wrapper">
      
     
         <div className="row">
            <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={gname}  onChange={(e)=>setGName(e.target.value)}  />
                </div>
            </div>
           
            
          
            
            
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button'onClick={()=>addGroupApi()} >Add now</button>
        </div>
    </div>
            </div></div>}
   
<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Create Customer</h2>
     
         <div className="row">
         <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select Customer category</label>
                <select className='input' value={customerCategory} onChange={(e)=>setCustomerCategory(e.target.value)}>

                    <option value="">Select</option>
                    <option value="single">Single</option>
                    <option value="group">Group</option>

                </select>
                </div>
            </div>
            {customerCategory =='group' &&  <div className="col-12 col-md-6">
            <label htmlFor="">Select group</label>
            <div className="categories-wrapper">
               
                <select className='input' value={groupId} onChange={(e)=>setGroupId(e.target.value)}>
                <option value="">Please select</option>
                   {groups?.map((group)=><option key={group?.id} value={group?.id} >{group?.name}</option>)}
                </select>
              <button onClick={()=>setModal((pre)=>!pre)}>Add Group</button>
                </div>
            </div>}
           
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Company Address</label>
                <input type="text" className='input' value={companyAddress}  onChange={(e)=>setCompanyAddress(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Postal Address</label>
                <input type="text" className='input' value={postalAddress}  onChange={(e)=>setPostalAddress(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Factory Phone 1</label>
                <input type="text" className='input' value={phone1}  onChange={(e)=>setPhone1(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Factory Phone 2</label>
                <input type="text" className='input' value={phone2}  onChange={(e)=>setPhone2(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Factory Phone 3</label>
                <input type="text" className='input' value={phone3}  onChange={(e)=>setPhone3(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head Office Address</label>
                <input type="text" className='input' value={headOfficeAddress}  onChange={(e)=>setHeadOfficeAddress(e.target.value)}  />
                </div>
            </div>
          
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office phone 1</label>
                <input type="text" className='input' value={headOfficePhone1}  onChange={(e)=>setHeadOfficePhone1(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office phone 2</label>
                <input type="text" className='input' value={headOfficePhone2}  onChange={(e)=>setHeadOfficePhone2(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office phone 3</label>
                <input type="text" className='input' value={headOfficePhone3}  onChange={(e)=>setHeadOfficePhone3(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Customer Type</label>
                <select className='input' value={isRegistered} onChange={(e)=>setIsRegistered(e.target.value)}>

                    <option value="">Select</option>
                    <option value="tax_register">Tax Register</option>
                    <option value="not_register">Not Registered</option>

                </select>
                </div>
            </div>

            {isRegistered == 'tax_register' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select if Registered in NTN</label>
                <select className='input' value={isNTN} onChange={(e)=>setIsNTN(e.target.value)}>

                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>

                </select>
                </div>
            </div>}

            {((isRegistered == 'tax_register') && (isNTN == '1')) &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor=""> NTN Number</label>
                <input type="text" className='input' value={NTNNumber}  onChange={(e)=>setNTNNumber(e.target.value)}  />
                </div>
            </div>}
           
            {isRegistered == 'tax_register' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select if Vendor Salex Tax Registered</label>
                <select className='input' value={isSalexTaxReGistered} onChange={(e)=>setIsSalexTaxReGistered(e.target.value)}>

                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>

                </select>
                </div>
            </div>}
            {((isRegistered == 'tax_register') && (isSalexTaxReGistered== '1')) &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Sales Tax Number</label>
                <input type="text" className='input' value={salexTaxNumber}  onChange={(e)=>setSalesTaxNumber(e.target.value)}  />
                </div>
            </div>}
            {isRegistered == 'tax_register' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select if Registered in FTN</label>
                <select className='input' value={isFTN} onChange={(e)=>setIsFTN(e.target.value)}>

                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>

                </select>
                </div>
            </div>}

            {((isRegistered == 'tax_register') && (isFTN == '1')) &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor=""> FTN Number</label>
                <input type="text" className='input' value={FTNNumber}  onChange={(e)=>setFTNNumber(e.target.value)}  />
                </div>
            </div>}

           
            
           
          
           
         </div>

         <div className="term-tab">
        <button className={`button ${tab == 'terms' ? 'active' : ''}`} onClick={()=>setTab('terms')}>
            Business Terms
        </button>
        {((isRegistered  == 'tax_register') && ((isNTN == '1') || (isSalexTaxReGistered == '1') ||(isFTN == '1')  )) &&  <button className={`button ${tab == 'tax' ? 'active' : ''}`} onClick={()=>setTab('tax')}>
            Taxations
        </button> }
        <button className={`button ${tab == 'contact' ? 'active' : ''}`} onClick={()=>setTab('contact')}>
            Contact Infromations
        </button>
       
    </div>
  {/* Busines tersm fiels */}
    {tab == 'terms' &&    <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Debit Days</label>
                <input type="text" className='input' value={debitDays}  onChange={(e)=>setDebitDays(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Debit Bussiness Amount Limit</label>
                <input type="text" className='input' value={debitBussinessAmountLimit}  onChange={(e)=>setdebitBussinessAmountLimit(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Credit Overdue Amount Limit</label>
                <input type="text" className='input' value={debitOverdueAmountLimit}  onChange={(e)=>setdebitOverdueAmountLimit(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Debit Sales Amount Limit</label>
                <input type="text" className='input' value={debitSalesAmountLimit}  onChange={(e)=>setdebitSalesAmountLimit(e.target.value)}  />
                </div>
            </div>


            </div>
            
            }
        {/* Tax tab here */}

        {tab == 'tax' &&    <div className="row">
        <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select Incomewitheld Tax or manual</label>
                <select className='input' value={incomeTaxWithHeldType} onChange={(e)=>setIncomeTaxWithHeldType(e.target.value)}>

                    <option value="">Select</option>
                    <option value="tax">Tax</option>
                    <option value="manual">Mannual</option>

                </select>
                </div>
            </div>
         {/* Manual for withholding tax */}
            {incomeTaxWithHeldType == 'manual' && <div className="row"> <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                     Incomewitheld Tax  Ration
                    </span>
                   
                </div>
                <input type="text" className='input' value={manualIncomeTaxWithHeld} placeholder='Tax ratio' onChange={(e)=>setMannualIncomeTaxWithHeld(e.target.value)}  />
                </div>
            </div>

              <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Incomewitheld Tax Applicable date
                    </span>
                    
                </div>
                <input type="date" className='input' value={manualIncomeTaxWithHeldDate} placeholder='Tax ratio' onChange={(e)=>setMannualIncomeTaxWithHeldDate(e.target.value)}  />
                </div>
            </div>
            </div>
            
            }

            
          <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select  Saleswitheld Tax or manual</label>
                <select className='input' value={salesWithHeldType} onChange={(e)=>setSalesWithHeldType(e.target.value)}>

                    <option value="">Select</option>
                    <option value="tax">Tax</option>
                    <option value="manual">Mannual</option>

                </select>
                </div>
            </div>

             {/* Manual forSaleswitheld Tax */}

            {salesWithHeldType == 'manual' && <div className="row"> <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Saleswitheld Taxx  Ratio
                    </span>
                    
                </div>
                <input type="text" className='input' value={manualSalesWithHeldTax} placeholder='Tax ratio' onChange={(e)=>setMannualSalesWithHeldTax(e.target.value)}  />
                </div>
            </div>

             <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Saleswitheld Tax Applicable date
                    </span>
                    
                </div>
                <input type="date" className='input' value={manualSalesWithHeldDate} placeholder='Tax ratio' onChange={(e)=>setMannualSalesWithHeldDate(e.target.value)}  />
                </div>
            </div>
            </div>
            
            }


            </div>
            
            }


{/* Conatcte tab here */}

{tab == 'contact' &&  
        
        <div className="contact-tab">
              {/* =============== Purchase person html =========== */}
            <div className="title mb-1">
                Purchase person info
            </div>
            {purchasePersons?.map((person,index)=> <div className='row mb-0 relative' key={index}>
                {purchasePersons.length > 1 && <div className="close" onClick={()=>removePurchasePerson(index)}>
                   <MdOutlineClose />
                </div>}
                <div className="col-12 mb-2 title">
                    Person {index + 1}
                </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={person.name}  onChange={(e)=>handlePurchase(index,'name',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Designation</label>
                <input type="text" className='input' value={person.designation}  onChange={(e)=>handlePurchase(index,'designation',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Mobile</label>
                <input type="text" className='input' value={person.mobile}  onChange={(e)=>handlePurchase(index,'mobile',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Phone</label>
                <input type="text" className='input' value={person.phone}  onChange={(e)=>handlePurchase(index,'phone',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Fax</label>
                <input type="text" className='input' value={person.fax}  onChange={(e)=>handlePurchase(index,'fax',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Email</label>
                <input type="text" className='input' value={person.email}  onChange={(e)=>handlePurchase(index,'email',e.target.value)}  />
                </div>
            </div>
                
                 </div> )}

                 <div className="my-3 d-flex justify-content-center">
                    <button className="button" onClick={addPurchasePerson}>Add purchase persons</button>
                 </div> 



                  {/* =============== Store person html =========== */}
            <div className="title mb-1">
                Store person info
            </div>
            {storePersons?.map((person,index)=> <div className='row mb-0 relative' key={index}>
                {storePersons.length > 1 && <div className="close" onClick={()=>removeStorePerson(index)}>
                   <MdOutlineClose />
                </div>}
                <div className="col-12 mb-2 title">
                    Person {index + 1}
                </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={person.name}  onChange={(e)=>handleStore(index,'name',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Designation</label>
                <input type="text" className='input' value={person.designation}  onChange={(e)=>handleStore(index,'designation',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Mobile</label>
                <input type="text" className='input' value={person.mobile}  onChange={(e)=>handleStore(index,'mobile',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Phone</label>
                <input type="text" className='input' value={person.phone}  onChange={(e)=>handleStore(index,'phone',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Fax</label>
                <input type="text" className='input' value={person.fax}  onChange={(e)=>handleStore(index,'fax',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Email</label>
                <input type="text" className='input' value={person.email}  onChange={(e)=>handleStore(index,'email',e.target.value)}  />
                </div>
            </div>
                
                 </div> )}

                 <div className="my-3 d-flex justify-content-center">
                    <button className="button" onClick={addStorePerson}>Add store persons</button>
                 </div> 



                  {/* =============== Invoice person html =========== */}
                  <div className="title mb-1">
                Invoice person info
            </div>
            {invoicePersons?.map((person,index)=> <div className='row mb-0 relative' key={index}>
                {invoicePersons.length > 1 && <div className="close" onClick={()=>removeInvoicePerson(index)}>
                   <MdOutlineClose />
                </div>}
                <div className="col-12 mb-2 title">
                    Person {index + 1}
                </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={person.name}  onChange={(e)=>handleInvoice(index,'name',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Designation</label>
                <input type="text" className='input' value={person.designation}  onChange={(e)=>handleInvoice(index,'designation',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Mobile</label>
                <input type="text" className='input' value={person.mobile}  onChange={(e)=>handleInvoice(index,'mobile',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Phone</label>
                <input type="text" className='input' value={person.phone}  onChange={(e)=>handleInvoice(index,'phone',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Fax</label>
                <input type="text" className='input' value={person.fax}  onChange={(e)=>handleInvoice(index,'fax',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Email</label>
                <input type="text" className='input' value={person.email}  onChange={(e)=>handleInvoice(index,'email',e.target.value)}  />
                </div>
            </div>
                
                 </div> )}

                 <div className="my-3 d-flex justify-content-center">
                    <button className="button" onClick={addInvoicePerson}>Add Invoice persons</button>
                 </div> 


                    {/* =============== Ledgers person html =========== */}
                    <div className="title mb-1">
                    Ledgers person info
            </div>
            {ledgersPersons?.map((person,index)=> <div className='row mb-0 relative' key={index}>
                {ledgersPersons.length > 1 && <div className="close" onClick={()=>removeLedgersPerson(index)}>
                   <MdOutlineClose />
                </div>}
                <div className="col-12 mb-2 title">
                    Person {index + 1}
                </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={person.name}  onChange={(e)=>handleLedgers(index,'name',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Designation</label>
                <input type="text" className='input' value={person.designation}  onChange={(e)=>handleLedgers(index,'designation',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Mobile</label>
                <input type="text" className='input' value={person.mobile}  onChange={(e)=>handleLedgers(index,'mobile',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Phone</label>
                <input type="text" className='input' value={person.phone}  onChange={(e)=>handleLedgers(index,'phone',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Fax</label>
                <input type="text" className='input' value={person.fax}  onChange={(e)=>handleLedgers(index,'fax',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Email</label>
                <input type="text" className='input' value={person.email}  onChange={(e)=>handleLedgers(index,'email',e.target.value)}  />
                </div>
            </div>
                
                 </div> )}

                 <div className="my-3 d-flex justify-content-center">
                    <button className="button" onClick={addLedgersPerson}>Add Ledgers persons</button>
                 </div> 



                 {/* =============== Account person html =========== */}
                 <div className="title mb-1">
                    Account person info
            </div>
            {accountPersons?.map((person,index)=> <div className='row mb-0 relative' key={index}>
                {accountPersons.length > 1 && <div className="close" onClick={()=>removeAccountPerson(index)}>
                   <MdOutlineClose />
                </div>}
                <div className="col-12 mb-2 title">
                    Person {index + 1}
                </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={person.name}  onChange={(e)=>handleAccount(index,'name',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Designation</label>
                <input type="text" className='input' value={person.designation}  onChange={(e)=>handleAccount(index,'designation',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Mobile</label>
                <input type="text" className='input' value={person.mobile}  onChange={(e)=>handleAccount(index,'mobile',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Phone</label>
                <input type="text" className='input' value={person.phone}  onChange={(e)=>handleAccount(index,'phone',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Fax</label>
                <input type="text" className='input' value={person.fax}  onChange={(e)=>handleAccount(index,'fax',e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="form-group">
                <label htmlFor="">Email</label>
                <input type="text" className='input' value={person.email}  onChange={(e)=>handleAccount(index,'email',e.target.value)}  />
                </div>
            </div>
                
                 </div> )}

                 <div className="my-3 d-flex justify-content-center">
                    <button className="button" onClick={addAccountPerson}>Add Account persons</button>
                 </div> 


        </div>
        

}


<div className="d-flex justify-content-center gap-3 mt-3">
<Link className='button me-3' to='/customers' >Cancel</Link>
            <button className='button'onClick={()=>addCustomer()} >Add Customer Data Now</button>
        </div>
    </div>
 </div>
        </div>
    </div>

   
</div>

    </div>
  )
}

export default CreateCustomer