import React from 'react'
import { Link } from 'react-router-dom'

const ProductBar = () => {
  return (
    <div className='product-bar'>
        
         <Link className='button' to="/categories">Tools Categories</Link>
         <Link className='button' to="/brands">Tools Brands</Link>
         <Link className='button' to="/products">Designed Product List </Link>
         <Link className='button' to="/create-product">Design New Product</Link>
         <Link className='button' to="/add-product-to-list">Product Profile for inventory</Link>
         <Link className='button' to="/products-list">Profile List of inventory Products</Link>
         <Link className='button' to="/draft-products">Draft Products</Link>
         
         <Link className='button' to="/draft-products-list">Draft Listed product</Link>
         
        
     
        
        
        
        
    </div>
  )
}

export default ProductBar