import React, { useEffect, useState } from "react";
import { Services } from "../../api/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import User from "../../images/user.png";
import Sidebar from "../Sidebar";
import { IoReturnDownBack } from "react-icons/io5";
import userAvatar from '../../images/user.png'
const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const getProductDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getProductDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setProduct(result?.data?.data?.product);
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getProductDetailFunction(id);
  }, []);
  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/products' className="back">
        <IoReturnDownBack  /> Go to Products
    </Link>
      <ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
        
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Product details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product name</p>
                    <p className="value">{product?.name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                 
                    <div className="value"> <div dangerouslySetInnerHTML={{
                                __html:product?.description,
                              }}>
                                
                            </div> </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Units of measure</p>
                    <p className="value">{product?.unit_of_measure}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Status</p>
                    <p className="value">{product?.is_publis == '0' ? 'Draft' : 'Published'}</p>
                  </div>
                </div>
                
                
              
               
              </div>

              {product?.dynamic_form_field_values?.length > 0 && <>
                <p className="tabble-title">Product specifications</p>
               <div className="table-responsive">
            <table className='table'>
                <thead>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Value</th>
                    
                </thead>
                <tbody>
                    {product?.dynamic_form_field_values?.map((field,id)=> <tr key={id}>
                        <td>{field?.id} </td>
                        <td>{field?.key}</td>
                        <td>{field?.value}</td>
                        
                    </tr>)}
                </tbody>
            </table>
        </div></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
