import axios from 'axios';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = 'https://account.himalayatool.com/api'
// axios.defaults.headers.common['Authorization'] = 'Bearer'+AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';

//---- LOGIN USER ---------------
async function login(data){
    try {
     var config = {
         method: 'post',
         url: '/login',
         data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
         return err.response
    }
}
 


//post company profile
async function createCompany(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    // if (business_name !== undefined) queryParams.append('business_name', business_name);
    // if (profile_intro_dealing !== undefined) queryParams.append('profile_intro_dealing', profile_intro_dealing);
    // if (business_type !== undefined) queryParams.append('business_type', business_type);
    // if (business_premises_address !== undefined) queryParams.append('business_premises_address', business_premises_address);
    // if (national_tax_registration !== undefined) queryParams.append('national_tax_registration', national_tax_registration);
    // if (sales_tax_registration !== undefined) queryParams.append('sales_tax_registration', sales_tax_registration);
    // if (sales_tax_chargeable !== undefined) queryParams.append('sales_tax_chargeable', sales_tax_chargeable);
   

    
    // if (accounting_year_start !== undefined) queryParams.append('accounting_year_start', accounting_year_start);
    // if (accounting_year_end !== undefined) queryParams.append('accounting_year_end', accounting_year_end);
    // if (accounting_method !== undefined) queryParams.append('accounting_method', accounting_method);
    // if (documentary_detail_to_show !== undefined) queryParams.append('documentary_detail_to_show', documentary_detail_to_show);
    // if (quotation !== undefined) queryParams.append('quotation', quotation);
    // if (invoices !== undefined) queryParams.append('invoices', invoices);
    // if (user_id !== undefined) queryParams.append('user_id', user_id);
 
  
       var config = {
           method: 'post',
           url: `/companies/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get compnies list

async function getCompnies(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: '/companies',
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//get compniy detail

async function getCompnanyDetail(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/companies/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//edit company 
async function EditCompanyService(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
 
 
  
       var config = {
           method: 'post',
           url: `/companies/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//delete company 
async function deleteCompany(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/companies/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//post user profile
async function AddUser(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/user/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//update user profile
async function UpdateUser(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/users/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get users list

async function getUsers(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: '/users',
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//get compniy detail

async function getUserDetail(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/users/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//delete user 
async function deleteUser(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/users/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}


//Categories section
// create cetegory
async function createCategory(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/categories/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// create cetegory
async function updateCategory(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/categories/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// create cetegory
async function categoryDetail(id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/categories/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// get cetegories
async function getCategories() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/categories`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

//delete category
async function deleteCategory(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/categories/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//Modals section
//add modal
async function createModel(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/models/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get model detail
async function getModelDetail(id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/models/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//update modal

async function updateModel(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/models/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// get Models
async function getModels() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/models`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

//delete brand
async function deleteModel(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/models/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//Brands section
// create brand
async function createBrand(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/brands/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// brand detail
async function getBrandDetail(id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/brands/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data :''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// update brand
async function updateBrand(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/brands/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
// get Brands
async function getBrands() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/brands`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get category brands
// get Brands
async function getCategoryBrands(catid) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/get_brands/${catid}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

//delete brand
async function deleteBrand(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/brands/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//Products section start
//create product

async function createProduct(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/products/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

//update product
async function updateProduct(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/products/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get product list

async function getProducts() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/products`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

// delete product
async function deleteProduct(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/products/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//get product detail


async function getProductDetail(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/products/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//products to list app
async function addProductToListAPi(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/products_to_lists/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//update product to list

//products to list app
async function updateProductToListAPi(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/products_to_lists/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get list
async function getProductsList() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/products_to_lists`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get product list single product

async function getProductListDetail(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/products_to_lists/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//Delet product

async function deleteProuctFromList(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/products_to_lists/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//update witholding income tax
async function withHoldingTaxIcome(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/withholdingtaxs/update/1`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get detail witholding income tax
async function getWithHoldingTaxIcomeDetail(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/withholdingtaxs/1`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}




//update witholding income tax
async function withHoldingTaxSales(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/withholdingtaxs/update/2`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get detail witholding sales tax
async function getWithHoldingTaxSalesDetail(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/withholdingtaxs/2`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}


///chargebale taxes apis




//get detail witholding sales tax

//update chargeble register tax detail
async function updateChargableRegisterTax(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: `chargeablesalestax/update/1`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//get chargeble register tax detail
async function getChargableRegisterDetail(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `chargeablesalestax/1`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//update chargeble national tax detail
async function updateChargableNTNTax(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: `chargeablesalestax/update/2`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//get chargeble register tax detail
async function getChargableNTNDetail(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `chargeablesalestax/2`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//sales tax on vendor

//update Taxonpurchase detail
async function updateTaxPurchase(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: `salestaxonpurchase/update/1`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//get chargeble register tax detail
async function getTaxPurchaseDetail(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `salestaxonpurchase/1`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//enquiries apis
async function getEnquiries() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/enquiries`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

// delete product
async function deleteEnquiry(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/enquiries/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//creat enquiry

async function createEnquiry(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/enquiry/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

//creat enquiry

async function updateEnquiry(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/enquiries/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get enquiry detail
async function getEnquiryDetail(id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/enquiries/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//select company

async function selectCompany(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/users/update_company`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

//inventories list

// inventories
async function getInventories() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');


 
  
       var config = {
           method: 'get',
           url: `/inventories`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

// inventory detail
async function getInventoryDetail(id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');


 
  
       var config = {
           method: 'get',
           url: `/inventories/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}


//create inventory
async function createInventory(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');


 
  
       var config = {
           method: 'post',
           url: `/inventory/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}




//update inventory
async function updateInventoryApi(data,id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');


 
  
       var config = {
           method: 'post',
           url: `/inventories/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//delete inventory
async function deleteInventory(id) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');


 
  
       var config = {
           method: 'delete',
           url: `/inventories/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data :''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}


export const Services= { login,getCompnies,createCompany,AddUser,UpdateUser,getUsers,getCompnanyDetail,EditCompanyService,deleteCompany,deleteUser,getUserDetail,createCategory,getCategories,updateCategory,categoryDetail,createBrand,deleteCategory,updateBrand,getBrandDetail,getBrands,getCategoryBrands,deleteBrand,createProduct,updateProduct,getProducts,deleteProduct,getProductDetail,createModel,updateModel,getModelDetail,getModels,deleteModel,addProductToListAPi,updateProductToListAPi,getProductsList,deleteProuctFromList,getProductListDetail,withHoldingTaxIcome,getWithHoldingTaxIcomeDetail,withHoldingTaxSales,getWithHoldingTaxSalesDetail,updateChargableRegisterTax,getChargableRegisterDetail,updateChargableNTNTax,getChargableNTNDetail,updateTaxPurchase,getTaxPurchaseDetail,getEnquiries,deleteEnquiry,createEnquiry,getEnquiryDetail,updateEnquiry,selectCompany,createInventory,getInventories,deleteInventory,updateInventoryApi,getInventoryDetail}