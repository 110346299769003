import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import userAvatar from '../../images/user.png'
import { useNavigate,Link } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";

const Categories = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [categories,setCategories]=useState([])
   
const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[])
useEffect(()=>{
    getCategoriesList()
},[])
    const getCategoriesList = async ()=> {
        
            setIsLoading(true);
            const result = await Services.getCategories()
            console.log(result)
            if (result?.status === 200) {
            
           setCategories(result?.data?.data?.categories)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    //delete company api
    const deleteCategoryFunction = async (id) => {
        const isDelete = window.prompt('Do you want to delet category from list?');

        if (isDelete !== null) {
            setIsLoading(true);
            const result = await Services.deleteCategory(id);
            
            if (result?.status === 200) {
                toast.success("Company deleted from list", {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
                  getCategoriesList()
            } else {
              toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setIsLoading(false);
        } 
        
      };

  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/dashboard' className="back">
        <IoReturnDownBack  /> Go to Dashboard
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="container-fluid">
    <div className="row">
      
        <div className="col-12 col-md-12">
     <div className="container pt-4">
        <div className="compnies-list">
        <div className="d-flex justify-content-end mb-4">
        <Link className='button' to="/create-category">Creat Category</Link>
    </div>
        <div className="table-responsive">
            <table className='table'>
                <thead>
                <th>Id</th>
                    <th>Name</th>
                  
                   
                    <th>Action</th>
                </thead>
                <tbody>
                    {categories?.map((cat,id)=> <tr key={cat?.id}>
                    <td>{cat?.id} </td>
                        <td>{cat?.name} </td>
                       
                      
                       
                        <td><div className="actions">
                        <i class="fa-solid fa-pencil" title='Update company' onClick={()=>navigate(`/update-category/${cat?.id}`)}></i>
                       
                        <i class="fa-solid fa-trash-can" title='Delete company' onClick={()=>deleteCategoryFunction(cat?.id)}></i>
                            </div></td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        {categories?.length === 0 && <h4 className='text-center title_medium'>No record found</h4>}
        
     </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default Categories