
import React, {useState,useEffect} from 'react'

import { Services } from '../../api/Services';
import { ToastContainer, toast } from "react-toastify";
import { IoReturnDownBack } from "react-icons/io5";
import { CustomerServices } from '../../api/CustomerService';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import userAvatar from "../../images/user.png";
const CustomerDetail = () => {
    const navigate =useNavigate()
    const token = localStorage.getItem("token");
    useEffect(() => {
      if (!token) {
        navigate("/");
      }
    }, []);
    const { id } = useParams();
    const [loading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const getCustomerDetailApi = async (id) => {
        setIsLoading(true);
        const result = await CustomerServices.getCustomerDetail(id);
        console.log(result);
        if (result?.status === 200) {
          setCustomer(result?.data?.data);
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
      };
      useEffect(() => {
        getCustomerDetailApi(id);
      }, [id]);
  return (
    <div className='create-company dark-theme py-3'>
    
<Link to='/customers' className="back">
    <IoReturnDownBack  /> Go to Customer list
</Link>
<ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
         <div className="row">
       
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Customer details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer name</p>
                    <p className="value">{customer?.name}</p>
                  </div>
                </div>
               {customer?.address &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer Address</p>
                    <p className="value">{customer?.address}</p>
                  </div>
                </div>}
                {customer?.phone_1 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer Phone</p>
                    <p className="value">{customer?.phone_1}</p>
                  </div>
                </div>}
                {customer?.phone_2 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer Phone</p>
                    <p className="value">{customer?.phone_2}</p>
                  </div>
                </div>}
                {customer?.phone_3 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer Phone</p>
                    <p className="value">{customer?.phone_3}</p>
                  </div>
                </div>}
                
                  
              {customer?.head_office_address && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office address</p>
                    <p className="value">{customer?.head_office_address}</p>
                  </div>
                </div>}
               
                {customer?.head_office_phone_1 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office phone</p>
                    <p className="value">{customer?.head_office_phone_1}</p>
                  </div>
                </div>}
                
                {customer?.head_office_phone_2 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office phone</p>
                    <p className="value">{customer?.head_office_phone_2}</p>
                  </div>
                </div>}
                
                {customer?.head_office_phone_3 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office phone</p>
                    <p className="value">{customer?.head_office_phone_3}</p>
                  </div>
                </div>}
               
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">User Status</p>
                    <p className="value">{customer?.vendor_type == 'tax_register' ? "Registered" : 'Not Registered'}</p>
                  </div>
                </div>
                {customer?.sales_tax_number &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Sales Tax Number</p>
                    <p className="value">{customer?.sales_tax_number}</p>
                  </div>
                </div>}
                {customer?.ftn_number &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">FTN Number</p>
                    <p className="value">{customer?.ftn_number}</p>
                  </div>
                </div>}
                {customer?.ntn_number &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">FTN Number</p>
                    <p className="value">{customer?.ntn_number}</p>
                  </div>
                </div>}
                {customer?.income_tax_with_held_ratio && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Income Tax Ratio</p>
                    <p className="value">{customer?.income_tax_with_held_ratio} %</p>
                  </div>
                </div>}
                {customer?.sales_tax_with_held_ratio && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Sales Tax Ratio</p>
                    <p className="value">{customer?.sales_tax_with_held_ratio} %</p>
                  </div>
                </div>}
               
                 {customer?.income_tax_with_held_applicable_date &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Income Tax Applicable date</p>
                    <p className="value">{customer?.income_tax_with_held_applicable_date}</p>
                  </div>
                </div>}
               {customer?.sales_tax_with_held_applicable_date &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Sales Tax Applicable date</p>
                    <p className="value">{customer?.sales_tax_with_held_applicable_date}</p>
                  </div>
                </div>}
                
               {customer?.debit_days &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Debit days</p>
                    <p className="value">{customer?.debit_days}</p>
                  </div>
                </div>}
               {customer?.debit_business_amount_limit && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Credit Business Amount Limit</p>
                    <p className="value">{customer?.debit_business_amount_limit}</p>
                  </div>
                </div>}

                {customer?.debit_overdue_amount_limit && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Debit Over Due Amount Limit</p>
                    <p className="value">{customer?.debit_overdue_amount_limit}</p>
                  </div>
                </div>}
                
                {customer?.debit_sales_amount_limit &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Debit Sales Amount Limit</p>
                    <p className="value">{customer?.debit_sales_amount_limit}</p>
                  </div>
                </div>}
               
                
                
              
                
                
               
              </div>
              {
                (customer?.purchase_persons?.length > 0) && <div className='persons-wrapper'>
                <div className='title'> Purchase Persons</div>
                {customer?.purchase_persons?.map((itm,index)=> <div className='wrapper'>
                       <div className='subtitle'>Purchase person {index + 1}</div>
                       <div className='item'>
                       <div className='name'>Name</div>
                       <div className='value'>{itm?.name}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Email</div>
                       <div className='value'>{itm?.email}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Mobile Number</div>
                       <div className='value'>{itm?.mobile}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Phone</div>
                       <div className='value'>{itm?.phone}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Designation</div>
                       <div className='value'>{itm?.designation}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Fax</div>
                       <div className='value'>{itm?.fax}</div>
                       </div>

                </div>)}

              </div>
              }
              
              {
                (customer?.store_persons?.length > 0) && <div className='persons-wrapper'>
                <div className='title'> Store Persons</div>
                {customer?.store_persons?.map((itm,index)=> <div className='wrapper'>
                       <div className='subtitle'>Store person {index + 1}</div>
                       <div className='item'>
                       <div className='name'>Name</div>
                       <div className='value'>{itm?.name}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Email</div>
                       <div className='value'>{itm?.email}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Mobile Number</div>
                       <div className='value'>{itm?.mobile}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Phone</div>
                       <div className='value'>{itm?.phone}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Designation</div>
                       <div className='value'>{itm?.designation}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Fax</div>
                       <div className='value'>{itm?.fax}</div>
                       </div>

                </div>)}

              </div>
              }
               {
                (customer?.invoice_persons?.length > 0) && <div className='persons-wrapper'>
                <div className='title'> Invoice Persons</div>
                {customer?.invoice_persons?.map((itm,index)=> <div className='wrapper'>
                       <div className='subtitle'>Invoice person {index + 1}</div>
                       <div className='item'>
                       <div className='name'>Name</div>
                       <div className='value'>{itm?.name}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Email</div>
                       <div className='value'>{itm?.email}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Mobile Number</div>
                       <div className='value'>{itm?.mobile}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Phone</div>
                       <div className='value'>{itm?.phone}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Designation</div>
                       <div className='value'>{itm?.designation}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Fax</div>
                       <div className='value'>{itm?.fax}</div>
                       </div>

                </div>)}

              </div>
              }
               {
                (customer?.ledgers_persons?.length > 0) && <div className='persons-wrapper'>
                <div className='title'> Ledger Persons</div>
                {customer?.ledgers_persons?.map((itm,index)=> <div className='wrapper'>
                       <div className='subtitle'>Ledger person {index + 1}</div>
                       <div className='item'>
                       <div className='name'>Name</div>
                       <div className='value'>{itm?.name}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Email</div>
                       <div className='value'>{itm?.email}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Mobile Number</div>
                       <div className='value'>{itm?.mobile}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Phone</div>
                       <div className='value'>{itm?.phone}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Designation</div>
                       <div className='value'>{itm?.designation}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Fax</div>
                       <div className='value'>{itm?.fax}</div>
                       </div>

                </div>)}

              </div>
              }
               {
                (customer?.account_persons?.length > 0) && <div className='persons-wrapper'>
                <div className='title'> Account Persons</div>
                {customer?.account_persons?.map((itm,index)=> <div className='wrapper'>
                       <div className='subtitle'>Account person {index + 1}</div>
                       <div className='item'>
                       <div className='name'>Name</div>
                       <div className='value'>{itm?.name}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Email</div>
                       <div className='value'>{itm?.email}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Mobile Number</div>
                       <div className='value'>{itm?.mobile}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Phone</div>
                       <div className='value'>{itm?.phone}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Designation</div>
                       <div className='value'>{itm?.designation}</div>
                       </div>
                       <div className='item'>
                       <div className='name'>Fax</div>
                       <div className='value'>{itm?.fax}</div>
                       </div>

                </div>)}

              </div>
              }

           
        
            </div>
          </div>
        </div> 
      </div>
</div>
  )
}

export default CustomerDetail