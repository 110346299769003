
import React, {useState,useEffect} from 'react'

import { Services } from '../../api/Services';
import { ToastContainer, toast } from "react-toastify";
import { IoReturnDownBack } from "react-icons/io5";
import { CustomerServices } from '../../api/CustomerService';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import userAvatar from "../../images/user.png";
const VendorDetail = () => {
    const navigate =useNavigate()
    const token = localStorage.getItem("token");
    useEffect(() => {
      if (!token) {
        navigate("/");
      }
    }, []);
    const { id } = useParams();
    const [loading, setIsLoading] = useState(false);
    const [vendor, setVendor] = useState({});
    const getVendorDetailApi = async (id) => {
        setIsLoading(true);
        const result = await CustomerServices.getVendorDetail(id);
        console.log(result);
        if (result?.status === 200) {
          setVendor(result?.data?.data);
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
      };
      useEffect(() => {
        getVendorDetailApi(id);
      }, [id]);
  return (
    <div className='create-company dark-theme py-3'>
    
<Link to='/vendors' className="back">
    <IoReturnDownBack  /> Go to Vendors list
</Link>
<ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
         <div className="row">
       
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Vendor details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Venror name</p>
                    <p className="value">{vendor?.name}</p>
                  </div>
                </div>
               {vendor?.address &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Vendor Address</p>
                    <p className="value">{vendor?.address}</p>
                  </div>
                </div>}
                {vendor?.phone_1 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Vendor Phone</p>
                    <p className="value">{vendor?.phone_1}</p>
                  </div>
                </div>}
                {vendor?.phone_2 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Vendor Phone</p>
                    <p className="value">{vendor?.phone_2}</p>
                  </div>
                </div>}
                {vendor?.phone_3 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Vendor Phone</p>
                    <p className="value">{vendor?.phone_3}</p>
                  </div>
                </div>}
                
                  
              {vendor?.head_office_address && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office address</p>
                    <p className="value">{vendor?.head_office_address}</p>
                  </div>
                </div>}
               
                {vendor?.head_office_phone_1 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office phone</p>
                    <p className="value">{vendor?.head_office_phone_1}</p>
                  </div>
                </div>}
                
                {vendor?.head_office_phone_2 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office phone</p>
                    <p className="value">{vendor?.head_office_phone_2}</p>
                  </div>
                </div>}
                {vendor?.head_office_phone_3 && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office phone</p>
                    <p className="value">{vendor?.head_office_phone_3}</p>
                  </div>
                </div>}
               
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">User Status</p>
                    <p className="value">{vendor?.vendor_type == 'tax_register' ? "Registered" : 'Not Registered'}</p>
                  </div>
                </div>
                {vendor?.income_tax_with_held_ratio && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Income Tax Ratio</p>
                    <p className="value">{vendor?.income_tax_with_held_ratio} %</p>
                  </div>
                </div>}
                {vendor?.sales_tax_with_held_ratio && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Sales Tax Ratio</p>
                    <p className="value">{vendor?.sales_tax_with_held_ratio} %</p>
                  </div>
                </div>}
                 {vendor?.income_tax_with_held_applicable_date &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Income Tax Applicable date</p>
                    <p className="value">{vendor?.income_tax_with_held_applicable_date}</p>
                  </div>
                </div>}
               {vendor?.sales_tax_with_held_applicable_date &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Sales Tax Applicable date</p>
                    <p className="value">{vendor?.sales_tax_with_held_applicable_date}</p>
                  </div>
                </div>}
               {vendor?.credit_days &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Creit days</p>
                    <p className="value">{vendor?.credit_days}</p>
                  </div>
                </div>}
               {vendor?.credit_business_amount_limit && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Credit Business Amount Limit</p>
                    <p className="value">{vendor?.credit_business_amount_limit}</p>
                  </div>
                </div>}

                {vendor?.credit_overdue_amount_limit && <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Credit Over Due Amount Limit</p>
                    <p className="value">{vendor?.credit_overdue_amount_limit}</p>
                  </div>
                </div>}
                
                {vendor?.credit_sales_amount_limit &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Credit Sales Amount Limit</p>
                    <p className="value">{vendor?.credit_sales_amount_limit}</p>
                  </div>
                </div>}
               
                
                
              
                
                
               
              </div>

           
        
            </div>
          </div>
        </div> 
      </div>
</div>
  )
}

export default VendorDetail