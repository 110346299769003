import React, { useEffect, useState } from "react";
import { Services } from "../../api/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import User from "../../images/user.png";
import Sidebar from "../Sidebar";
import { IoReturnDownBack } from "react-icons/io5";
import { useLocation } from 'react-router-dom';


const EnquiryDetail = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [enquiryDetail, setEnquiryDetail] = useState({});

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const getEnquiryDetailApi = async (id) => {
    setIsLoading(true);
    const result = await Services.getEnquiryDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setEnquiryDetail(result?.data?.data?.enquiries);
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getEnquiryDetailApi(id);
  }, []);
  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to={`${enquiryDetail?.is_publish == '1' ? '/enquiries' : '/draft-enquiries'}`} className="back">
        <IoReturnDownBack  /> Go to Enquiries
    </Link>
      <ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
        
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Enquiry details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer name</p>
                    <p className="value">{enquiryDetail?.customer_name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Enquiry Status</p>
                    <p className="value">{enquiryDetail?.is_publish == '0' ? 'Draft' : 'Published'}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Enquiry date</p>
                    <p className="value">{enquiryDetail?.enquiery_date}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Customer reference number</p>
                    <p className="value">{enquiryDetail?.customer_enquiery_reference_no}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">System generated reference </p>
                    <p className="value">{enquiryDetail?.auto_system_enquiry_reference}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Enquiry type</p>
                    <p className="value">{enquiryDetail?.enquiry_type}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Quotation submit date</p>
                    <p className="value">{enquiryDetail?.quotation_submit_date}</p>
                  </div>
                </div>
               
                
                
                
              
               
              </div>

            </div>
          </div>
        </div>
        {(enquiryDetail?.enquiry_generate_type == 'form' || enquiryDetail?.enquiry_generate_type == 'fomr_&_images' || enquiryDetail?.enquiry_generate_type == 'form_images_files' ) &&
        <div className="container">
          <div className="title-main">
            Form 
          </div>
        <div className="row">
          <div className="col-12">
          <div className="compnies-list">
        <div className="table-responsive">
            <table className='table'>
                <thead>
                    <th>Product</th>
                    <th>Model</th>
                    <th>Brand</th>
                    <th>UOM</th>
                    
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>Image</th>
                </thead>
                <tbody>
                    {enquiryDetail?.enquiry_forms?.map((form,id)=> <tr key={id}>
                        <td>{form?.product} </td>
                        
                        <td>{form?.model}</td>
                        <td>{form?.brand}</td>
                        <td>{form?.uom}</td>
                        <td>{form?.qty}</td>
                        <td>{form?.description}</td>
                        <td><div className="imagee-td"> 
                        {form?.image ? <img src={`https://account.himalayatool.com/storage/uploads/images/${form?.image}`}  /> : <img src={User}  />}
                           </div></td>
                        
                        
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
          </div>
          </div>
        </div>}
        {(enquiryDetail?.enquiry_generate_type == 'images' || enquiryDetail?.enquiry_generate_type == 'fomr_&_images' || enquiryDetail?.enquiry_generate_type == 'form_images_files' ) &&
        <div className="container">
          <div className="title-main">
            Enquiry Images
          </div>
          <div className="row mb-5">
          <div className="col-12">
          <div className="images-preview detail-images">
                    {enquiryDetail?.enquiry_images?.map((image, index) => (
          <div key={index} className='single-image'>
            <img 
              src={`https://account.himalayatool.com/storage/uploads/images/${image?.file_name}`} 
              alt={`Uploaded ${index + 1}`} 
             
            />
           
          </div>
        ))}
                    </div>
          </div>
        </div>
        </div>}
        {(enquiryDetail?.enquiry_generate_type == 'attach_files'  || enquiryDetail?.enquiry_generate_type == 'form_images_files'  ) &&
        <div className="container">
            <div className="title-main">
            Enquiry Files
          </div>
           <div className="row">
          <div className="col-12">
          <div className="images-preview detail">
                    {enquiryDetail?.enquiry_files?.map((image, index) => (
          <div key={index} className='single-image files'>
            <div className="text">
           <a target="_blanl" href={`https://account.himalayatool.com/storage/uploads/files/${image?.file_name}`} download={`https://account.himalayatool.com/storage/uploads/images/${image?.file_name}`}>{image.file_name}</a> 
            </div>
            
          </div>
        ))}
                    </div>
          </div>
        </div>
        </div>
       }
      </div>
    </div>
  );
};

export default EnquiryDetail;
