import React,{useState,useEffect} from 'react'
import TaxesBar from './TaxesBar'
import { Link } from 'react-router-dom'
import { IoReturnDownBack } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Services } from '../../api/Services';
const SalesTaxOnPurchase = () => {
    const [loading,setIsLoading]=useState(false)
    // const [activeTab,setActiveTab]=useState('register')
    const [ratioPurchaseTax,setRatioPurchaseTax]=useState('')
    const [applicableDatePurchaseTax,setApplicableDatePurchaseTax]=useState('')
    const [readPurchase,setReadPurchase]=useState(false)
    const [isEditPurchase,setIsEditPurchase]=useState(false)

 
    //sales api calls
const taxDetail = async ()=> {
 
  
    
    setIsLoading(true)


   
  
    const result = await Services.getTaxPurchaseDetail()
    console.log(result)
    if (result?.status === 200) {
   setRatioPurchaseTax(result?.data?.data?.sales_tax_on_purchase_ratios[0]?.tax_ratio)
   setApplicableDatePurchaseTax(result?.data?.data?.sales_tax_on_purchase_ratios[0]?.applied_from)
   console.log(result)
   
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);

}

useEffect(()=>{
taxDetail()
},[])
//api call to create sales tax

const purchaseTaxCreate = async ()=> {
if(ratioPurchaseTax==""){
    toast.error("please enter ratio", {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
}

else {
    setIsLoading(true)
    const formData = new FormData();
    if(ratioPurchaseTax){
        formData.append("tax_ratio",ratioPurchaseTax)
    }
    if(ratioPurchaseTax){
        formData.append("type",'vendor')
    }
    if(applicableDatePurchaseTax){
        formData.append("applied_from",applicableDatePurchaseTax)
    }

   
  
    const result = await Services.updateTaxPurchase(formData)
    console.log(result)
    if (result?.status === 200) {
   
    toast.success(result?.data?.message, {
        autoClose: 1000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    setRatioPurchaseTax('');
    setApplicableDatePurchaseTax('')
    taxDetail()
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);
}
}
    
  return (
    <div className='create-company dark-theme py-3'>
    <TaxesBar />
    <Link to='/dashboard' className="back">
    <IoReturnDownBack  /> Go to dashboard
</Link>
<ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="taxes-wrapper">
   

        <div className="form-wrapper-tax form-wrapper ">
         <h2 className="title-small text-center">Register Sales Tax (Vendor)</h2>
         <div className="row">
            <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Ratio
                    </span>
                    <span className="edit" onClick={()=>setReadPurchase((pre)=>!pre)}>
                    <CiEdit /> <span>Edit</span>
                    </span>
                </div>
                <input type="text"  readOnly={readPurchase} className='input' value={ratioPurchaseTax} placeholder='Tax ratio' onChange={(e)=>setRatioPurchaseTax(e.target.value)}  />
                </div>
            </div>
         <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Applicable Date
                    </span>
                    
                </div>
                <input type="date" readOnly={readPurchase} className='input' value={applicableDatePurchaseTax} placeholder='Tax ratio' onChange={(e)=>setApplicableDatePurchaseTax(e.target.value)}  />
                </div>
            </div>
            </div>
            <div className="d-flex justify-content-center my-4">
                 <button className="button" onClick={purchaseTaxCreate}>Save Now</button>
            </div>
         
         </div>

         
</div>
</div>
  )
}

export default SalesTaxOnPurchase