import React, { useEffect, useState } from "react";
import { Services } from "../../api/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import User from "../../images/user.png";

import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import userAvatar from "../../images/user.png";
const ProductListDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);
 
  const getProductListDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getProductListDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setProduct(result?.data?.data?.products_to_list);
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getProductListDetailFunction(id);
  }, []);
  return (
    <div className='create-company dark-theme py-3'>
    <ProductBar />
<Link to='/products-list' className="back">
    <IoReturnDownBack  /> Go to listed products
</Link>
      <ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
       
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Product details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product name</p>
                    <p className="value">{product?.products?.name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product Status</p>
                    <p className="value">{product?.is_publish == '0' ? 'Draft' : 'Published'}</p>
                  </div>
                </div>
               
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Units of measure</p>
                    <p className="value">{product?.unit_of_measure}</p>
                  </div>
                </div>
                
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product Image</p>
                    <div className="product-image">
                    {product?.picture ? <img src={`https://account.himalayatool.com/storage/uploads/${product?.picture}`}  /> : <img src={userAvatar}  />}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Technical sheet</p>
                    <div className="product-image">
                    {product?.technical_sheet ? <a href={`https://account.himalayatool.com/storage/uploads/${product?.technical_sheet}`} target="_blank" >Technical sheet</a> :<span>No file found</span> }
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Model number</p>
                    <p className="value">{product?.model_id}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Brand name</p>
                    <p className="value">{product?.brands?.name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Brand rigin</p>
                    <p className="value">{product?.brands?.origin}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product origin</p>
                    <p className="value">{product?.origin}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Parts availibilty</p>
                    <p className="value">{product?.parts_availability}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Warrenty</p>
                    <p className="value">{product?.warrenty}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Warrenty duration</p>
                    <p className="value">{product?.warrenty_duration}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product Expiry date</p>
                    <p className="value">{product?.product_expiray_date}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product Type</p>
                    <p className="value">{product?.product_type}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product Markete type</p>
                    <p className="value">{product?.product_markete}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product costing method</p>
                    <p className="value">{product?.product_costing_method}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Opening stock</p>
                    <p className="value">{product?.opening_stock}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Cost of purchase</p>
                    <p className="value">{product?.cost_of_purchase}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Vendor name</p>
                    <p className="value">{product?.vendor_name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Minimum stock</p>
                    <p className="value">{product?.minimum_stock}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Custom Hs Code</p>
                    <p className="value">{product?.custom_hs_code}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div className="company-detail-item">
                    <p className="title">Product Description</p>
                    <p className="value" dangerouslySetInnerHTML={{
                                __html:product?.description}}></p>
                  </div>
                </div>
               
              </div>

              {product?.products?.dynamic_form_field_values?.length > 0 && <>
              <p className="tabble-title">Product specifications</p>
               <div className="table-responsive">
            <table className='table'>
                <thead>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Value</th>
                    
                </thead>
                <tbody>
                    {product?.products?.dynamic_form_field_values?.map((field,id)=> <tr key={id}>
                        <td>{field?.id} </td>
                        <td>{field?.key}</td>
                        <td>{field?.value}</td>
                        
                    </tr>)}
                </tbody>
            </table>
        </div></>}
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListDetail;
