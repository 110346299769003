import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { CustomerServices } from '../../api/CustomerService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import ReactQuill from 'react-quill';
import { IoIosClose } from "react-icons/io";
import 'react-quill/dist/quill.snow.css';
const CreateEnquiry = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [customers,setCustomers]=useState([])
    const [enquiryDate,setEnquiryDate]=useState('')
    const [customerName,setCustomerName]=useState('')
    const [customerEnquiryReference,setCustomerEnquiryReference]=useState('')
    const [enquiryType,setEnquiryType]=useState('')
    const [quotationSubmitDate,setQuotationSubmitDate]=useState('')
    const [quotationType,setQuotationType]=useState('')
    const [type,setType]=useState('')
    const [imagesList,setImagesList]=useState([])
    const [imagesPreview,setImagesPreview]=useState([])
    const [filesList,setFilesList]=useState([])
    const [filesPreview,setFilesPreview]=useState([])


    useEffect(()=>{
      getCustomersList()
  },[])
      const getCustomersList = async ()=> {
          
              setIsLoading(true);
              const result = await CustomerServices.getCustomers()
              console.log(result)
              if (result?.status === 200) {
              
             setCustomers(result?.data?.data?.customers)
                
              }
              else {
                  toast.error(result?.data?.message, {
                      autoClose: 2000,
                      pauseOnHover: true,
                      draggable: true,
                      position: toast.POSITION.TOP_CENTER,
                    });
              }
              setIsLoading(false);
          }
    const [inputs, setInputs] = useState([
        { product: "", description: "", brand: "",model:"",uom:"",qty:"" },
      ]);

      const handleInputChange = (index, field, value) => {
        const newInputs = [...inputs];
        newInputs[index][field] = value;
        setInputs(newInputs);
      };
    
      const handleFileChange = (index, field, file) => {
        const newInputs = [...inputs];
        newInputs[index][field] = file;
        setInputs(newInputs);
      };
       // add new plan
  const addNewInputs = () => {
    setInputs([
      ...inputs,
      { product: "", description: "", brand: "",model:"",uom:"",qty:"" },
    ]);
  };
  //remove plan
  const removeInputs = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };
const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])

//handle images changes

const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const filePreviews = files.map(file => URL.createObjectURL(file));
    setImagesPreview(prevImages => [...prevImages, ...filePreviews]);
    setImagesList(prevFiles => [...prevFiles, ...files]);
  };

//handle remove image
const removeImage = (imageToRemove, indexToRemove) => {
    setImagesPreview(prevImages => prevImages.filter(image => image !== imageToRemove));
    setImagesList(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    URL.revokeObjectURL(imageToRemove); // Revoke the object URL
  };
  useEffect(() => {
    return () => {
      // Revoke the object URLs to avoid memory leaks
      imagesList.forEach(url => URL.revokeObjectURL(url));
    };
  }, [imagesList]);
//handle files changes


const handleFilesChange = (e) => {
    const files = Array.from(e.target.files);
    const filePreviews = files.map(file => URL.createObjectURL(file));
    setFilesPreview(prevImages => [...prevImages, ...filePreviews]);
    setFilesList(prevFiles => [...prevFiles, ...files]);
  };

//handle remove image
const removeFile = (imageToRemove, indexToRemove) => {
    setFilesPreview(prevImages => prevImages.filter(image => image !== imageToRemove));
    setFilesList(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    URL.revokeObjectURL(imageToRemove); // Revoke the object URL
  };
  useEffect(() => {
    return () => {
      // Revoke the object URLs to avoid memory leaks
      filesList.forEach(url => URL.revokeObjectURL(url));
    };
  }, [filesList]);

//add categor


 


 
    const addEnquiry = async (ispublish)=> {
        if(quotationType==""){
            toast.error("please Select Quotation type", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            const formData = new FormData();
            if(customerName){
                formData.append("customer_name",customerName)
            }
            if(ispublish) {
              formData.append('is_publish',(ispublish == '1' || ispublish == '2') ? 1 : 0)
          }
            if(enquiryDate){
                formData.append('enquiery_date',enquiryDate)
            }
            
          
            if(customerEnquiryReference){
                formData.append('customer_enquiery_reference_no',customerEnquiryReference)
            }
            if(enquiryType){
                formData.append('enquiry_type',enquiryType)
            }
            if(quotationSubmitDate){
                formData.append('quotation_submit_date',quotationSubmitDate)
            }
            if(quotationSubmitDate){
                formData.append('quotation_submit_date',quotationSubmitDate)
            }
            if(quotationType){
                formData.append('enquiry_generate_type',quotationType)
            }
            if(imagesList){
                imagesList?.map((img,index)=>  formData.append(`images_list[${index}]`,img))
               
            }
            if(filesList){
                filesList?.map((img,index)=>  formData.append(`files_list[${index}]`,img))
               
            }
            formData.append('type','enquiery')
        
            if(inputs) {
                inputs?.forEach((item, index) => {
                    for (const key in item) {
                      if (key === "image" && item[key] instanceof File) {
                        formData.append(`form_data[${index}][${key}]`, item[key]);
                      } else {
                        formData.append(`form_data[${index}][${key}]`, item[key]);
                      }
                    }
                  });
              
             }
      
         
            setIsLoading(true);
            const result = await Services.createEnquiry(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Enquiry added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              if(ispublish == 0){
                navigate('/draft-enquiries')
              }
              else if (ispublish == 2) {
                navigate('/enquiries')
              }
              else {
                setCustomerName('')
                setCustomerEnquiryReference('')
                setEnquiryDate('');
                setEnquiryType('');
                setQuotationSubmitDate('')
                setQuotationType('')
              }
              
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    

   

 


 
    


  return (
    <div className='create-company dark-theme py-3'>
      
    <Link to='/enquiries' className="back">
        <IoReturnDownBack  /> Go to Enquiries
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}



<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Enquiry Form</h2>
     
         <div className="row">
         <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Customer name</label>
                <select name="" id="" className="name" value={customerName}  onChange={(e)=>setCustomerName(e.target.value)}>
                  <option value="">Please select</option>
                  {customers?.map((cus)=> <option value={cus?.name}>{cus?.name}</option>)}
                </select>
               
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Customer Enquiry Reference Number</label>
                <input type="text" className='input' value={customerEnquiryReference}  onChange={(e)=>setCustomerEnquiryReference(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Enquiry Date</label>
                <input type="date" className='input' value={enquiryDate}  onChange={(e)=>setEnquiryDate(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Quotation Submit Date</label>
                <input type="date" className='input' value={quotationSubmitDate}  onChange={(e)=>setQuotationSubmitDate(e.target.value)}  />
                </div>
            </div>
          
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Enquiry Type</label>
                <select name="" id="" value={enquiryType}  onChange={(e)=>setEnquiryType(e.target.value)}>
                    <option value="">Select Enquiry Type</option>
                    <option value="phone_call">Phone</option>
                    <option value="fax">Fax</option>
                    <option value="email">Email</option>
                    
                </select>
              
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Enquiry Generate Type</label>
                <select name="" id="" value={quotationType}  onChange={(e)=>setQuotationType(e.target.value)}>
                    <option value="">Select Enquiry Generate Type</option>
                    <option value="form">Form</option>
                    <option value="images">Images</option>
                    <option value="fomr_&_images">Form and Images</option>
                    <option value="attach_files">Attach Files</option>
                    <option value="form_images_files">Form ,Images and Files</option>
                </select>
              
                </div>
            </div>
           
            
           
        
        
        
    </div> 
    <div className="row mb-3">
        <div className="col-12">
        { (quotationType == 'form' || quotationType == 'fomr_&_images' || quotationType == 'form_images_files' ) &&
          <div>
            {inputs?.map((input, index) => (
              <div className="single-simulation row my-3" key={index}>
                {inputs.length > 1 && (
                  <div className="close" onClick={() => removeInputs(index)}>
                   
                    <IoIosClose />
                  </div>
                )}
                <div className="col-12 col-md-4">
                <input
                  type="text"
                  className="input"
                  value={input.product}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "product",
                      e.target.value
                    )
                  }
                  placeholder="Product"
                />
                </div>
                <div className="col-12 col-md-4">
                <input
                  type="text"
                  className="input"
                  value={input.description}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "description",
                      e.target.value
                    )
                  }
                  placeholder="Description"
                />
                </div>
                <div className="col-12 col-md-4">
                <input
                  type="text"
                  className="input"
                  value={input.brand}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "brand",
                      e.target.value
                    )
                  }
                  placeholder="Brand"
                />
                </div>
                <div className="col-12 col-md-4">
                <input
                  type="text"
                  className="input"
                  value={input.model}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "model",
                      e.target.value
                    )
                  }
                  placeholder="Model"
                />
                </div>
                <div className="col-12 col-md-4">
                <input
                  type="text"
                  className="input"
                  value={input.uom}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "uom",
                      e.target.value
                    )
                  }
                  placeholder="Unit of measure"
                />
                </div>
                <div className="col-12 col-md-4">
                <input
                  type="text"
                  className="input"
                  value={input.qty}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "qty",
                      e.target.value
                    )
                  }
                  placeholder="Quantity"
                />
                </div>
                {/* <div className="col-12">
                <input
                  type="file"
                  className="input"
                  
                  onChange={(e) =>
                    handleFileChange(index, "image", e.target.files[0])
                  }
                  placeholder="Product"
                />
                </div> */}
                
                
                  
                 
                  
                   
                  
                
              </div>
            ))}
           
            <div>
              <button className="button" onClick={addNewInputs}>
                Add More 
              </button>
            </div>
          </div> 
        }
        </div>
    </div>
    <div className="row mb-3">
        <div className="col-12">
            {(quotationType == 'images' || quotationType == 'fomr_&_images' || quotationType == 'form_images_files' ) && <div>
                
                <div className="row">
                    <div className="col-12 col-md-8">
                  
                <div className="form-group">
                <label htmlFor="">Upload images</label>
                <input type="file" multiple className='input' accept="image/*" onChange={handleImagesChange} />
              
                </div>
            </div>
                   
                </div>

                <div className="row">
                    <div className="images-preview">
                    {imagesPreview?.map((image, index) => (
          <div key={index} className='single-image'>
            <img 
              src={image} 
              alt={`Uploaded ${index + 1}`} 
             
            />
            <div  className='close'
              onClick={() => removeImage(image, index)} 
             
            >
              X
            </div>
          </div>
        ))}
                    </div>
                </div>
               
                </div>}
        </div>
    </div>

    <div className="row mb-3">
        <div className="col-12">
            {(quotationType == 'attach_files'  || quotationType == 'form_images_files' ) && <div>
                
                <div className="row">
                    <div className="col-12 col-md-8">
                  
                <div className="form-group">
                <label htmlFor="">Upload Files</label>
                <input type="file" className='input' accept=".pdf,.doc,.docx" multiple  onChange={handleFilesChange} />
              
                </div>
            </div>
                   
                </div>

                <div className="row">
                    <div className="images-preview">
                    {filesList?.map((image, index) => (
          <div key={index} className='single-image files'>
            <div className="text">
            {image.name}
            </div>
            <div  className='close'
              onClick={() => removeFile(image, index)} 
             
            >
              X
            </div>
          </div>
        ))}
                    </div>
                </div>
               
                </div>}
        </div>
    </div>
    <div className="text-center d-flex justify-content-center gap-4 m-3 ">

    <Link className='button me-3' to='/enquiries' >Cancel</Link>
            <button className='button'onClick={()=>addEnquiry('1')} >Save & New</button>
            <button className='button'onClick={()=>addEnquiry('0')} >Save As Draft</button>
            <button className='button'onClick={()=>addEnquiry('2')} >Save & Exit</button>
        </div>
 </div>
        </div>
    </div>
</div>

    </div>
    </div>
  )
}


export default CreateEnquiry