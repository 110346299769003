import axios from 'axios';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = 'https://account.himalayatool.com/api'
// axios.defaults.headers.common['Authorization'] = 'Bearer'+AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';


//vendors apis
//add vendor
async function addVendor(data) {
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
      // const queryParams = new URLSearchParams();
  
  
      
   
    
         var config = {
             method: 'post',
             url: `/vendors/store`,
             headers:{
              'Accept': 'application/json', 
              'Content-Type': 'multipart/form-data',
               
              'Authorization':'Bearer ' + AUTH_TOKEN
            },
             data : data
         };
         
       return await axios(config)
         .then(function (response) {
           return response;
         })
         .catch(function (error) {
           return error.response
       });
    } catch (err) {
      return err
    }
  }
//update
async function updateVednor(id,data) {
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
      // const queryParams = new URLSearchParams();
  
  
      
   
    
         var config = {
             method: 'post',
             url: `/vendors/update/${id}`,
             headers:{
              'Accept': 'application/json', 
              'Content-Type': 'multipart/form-data',
               
              'Authorization':'Bearer ' + AUTH_TOKEN
            },
             data : data
         };
         
       return await axios(config)
         .then(function (response) {
           return response;
         })
         .catch(function (error) {
           return error.response
       });
    } catch (err) {
      return err
    }
  }
  //get list
  async function getVendors() {
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
      // const queryParams = new URLSearchParams();
  
  
      
   
    
         var config = {
             method: 'get',
             url: `/vendors`,
             headers:{
              'Accept': 'application/json', 
              'Content-Type': 'application/json',
               
              'Authorization':'Bearer ' + AUTH_TOKEN
            },
             data : ''
         };
         
       return await axios(config)
         .then(function (response) {
           return response;
         })
         .catch(function (error) {
           return error.response
       });
    } catch (err) {
      return err
    }
  }
  //get vendor
  
  async function getVendorDetail(id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'get',
           url: `/vendors/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + AUTH_TOKEN
          },
           data : ""
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
  }
  //Delet vendor
  
  async function deleteVendor(id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'delete',
           url: `/vendors/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + AUTH_TOKEN
          },
           data : ""
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
  }



  //customers apis
//add customer
async function addCustomer(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/customers/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//update customer
async function updateCustomer(id,data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/customers/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/form-data',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get list
async function getCustomers() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/customers`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get customer

async function getCustomerDetail(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/customers/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//Delet customer

async function deleteCustomer(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/customers/${id}`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + AUTH_TOKEN
        },
         data : ""
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}

//add group

async function addGroup(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'post',
           url: `/groups/store`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'multipart/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
//get groups

async function getGroups() {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
    // const queryParams = new URLSearchParams();


    
 
  
       var config = {
           method: 'get',
           url: `/groups`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
             
            'Authorization':'Bearer ' + AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}

  export const CustomerServices= {addVendor,updateVednor,getVendors,getVendorDetail ,deleteVendor,addCustomer,updateCustomer,getCustomerDetail,getCustomers,deleteCustomer,addGroup,getGroups}