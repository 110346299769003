
import './App.css';
import Login from './components/Login';
import NotFound from './components/NotFound';
import Dashboard from './components/Dashboard';
import CreateCompany from './components/compnies/CreateCompany';
import Compnies from './components/compnies/Compnies';
import AddUser from './components/users/AddUser';
import UpdateUser from './components/users/UpdateUser';
import Users from './components/users/Users';
import CompnyDetail from './components/compnies/CompnyDetail';
import Categories from './components/categories/Categories';
import CreateCategory from './components/categories/CreateCategory';
import UpdateCategory from './components/categories/UpdateCategory';
import UserDetail from './components/users/UserDetail';
import EditCompany from './components/compnies/EditCompany';
import CreateBrand from './components/brands/CreateBrand';
import Brands from './components/brands/Brands';
import BrandDetail from './components/brands/BrandDetail';
import UpdateBrand from './components/brands/UpdateBrand';
import CreateProduct from './components/products/CreateProduct';
import UpdateProduct from './components/products/UpdateProduct';
import Products from './components/products/Products';
import ProductDetail from './components/products/ProductDetail';
import UpdateModel from './components/models/UpdateModel';
import ModelDetail from './components/models/ModelDetail';
import Models from './components/models/Models';
import CreateModel from './components/models/CreateModel';
import UpdateProductToList from './components/addproducttolist/UpdateProtuctToList';
import ProductsList from './components/addproducttolist/ProductsList';
import AddProuctToList from './components/addproducttolist/AddProtuctToList';
import ProductListDetail from './components/addproducttolist/ProductListDetail';
import WithholdingTaxes from './components/taxes/WithholdingTaxes';
import ChargeAbleSalesTax from './components/taxes/ChargeAbleSalesTax';
import SalesTaxOnPurchase from './components/taxes/SalesTaxOnPurchase';
import SelectCompany from './components/SelectCompany';
//Inventorues
import CreateInventory from './components/inentories/CreateInventory';

//vendors components
import CreateVendor from './components/vendors/CreateVendor';
import Vendors from './components/vendors/Vendors';
import VendorDetail from './components/vendors/VendorDetail';
import UpdateVendor from './components/vendors/UpdateVendor';
//customers components
import CreateCustomer from './components/customers/CreateCustomer';
import Customers from './components/customers/Customers';
import CustomerDetail from './components/customers/CustomerDetail';
import UpdateCustomer from './components/customers/UpdateCustomer';

//admin components
import AdminDashboard from './components/admin/Dashboard';
import Roles from './components/admin/roles/Roles';
import AddRole from './components/admin/roles/AddRole';
import UpdateRole from './components/admin/roles/UpdateRole';
import RoleDetail from './components/admin/roles/RoleDetail';
import Permissions from './components/admin/permissions/Permissions';
import AddPermission from './components/admin/permissions/AddPermission';
import UpdatePermission from './components/admin/permissions/UpdatePermission';
import PermissionDetail from './components/admin/permissions/PermissionDetail';

//Enquiries
import Enquiries from './components/enquiries/Enquiries';
import CreateEnquiry from './components/enquiries/CreateEnquiry';
import EnquiryDetail from './components/enquiries/EnquiryDetail';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import UpdateEnquiry from './components/enquiries/UpdateEnquiry';
import DraftProducts from './components/products/DraftProducts';
import DraftProductsList from './components/addproducttolist/DraftProductsList';
import DraftEnquiries from './components/enquiries/DraftEnquiries';
import Inventories from './components/inentories/Inventories';
import UpdateInventory from './components/inentories/UpdateInventory';
import InventoryDetail from './components/inentories/InventoryDetail';


function App() {
  return (
    <div >
    <Router>
            <Routes>
              
              <Route exact path='/'  element={<Login/>}/>
              <Route exact path='/dashboard'  element={<Dashboard/>}/>
              <Route exact path='/create-company'  element={<CreateCompany/>}/>
              <Route exact path='/compnies'  element={<Compnies/>}/>
              <Route exact path='/select-company'  element={<SelectCompany/>}/>
              <Route exact path='/add-user'  element={<AddUser/>}/>
              <Route exact path='/update-user/:id'  element={<UpdateUser/>}/>
              <Route exact path='/users'  element={<Users/>}/>
             
              <Route exact path='/user-detail/:id'  element={<UserDetail/>}/>
              <Route exact path='/company-detail/:id'  element={<CompnyDetail/>}/>
              <Route exact path='/edit-company/:id'  element={<EditCompany/>}/>
              <Route exact path='/categories'  element={<Categories/>}/>
              <Route exact path='/create-category'  element={<CreateCategory/>}/>
              <Route exact path='/update-category/:id'  element={<UpdateCategory/>}/>
              <Route exact path='/brands'  element={<Brands/>}/>
              <Route exact path='/brand-detail/:id'  element={<BrandDetail/>}/>
              <Route exact path='/create-brand'  element={<CreateBrand/>}/>
              <Route exact path='/update-brand/:id'  element={<UpdateBrand/>}/>
              <Route exact path='/create-product'  element={<CreateProduct/>}/>
              <Route exact path='/update-product/:id'  element={<UpdateProduct/>}/>
              <Route exact path='/products'  element={<Products/>}/>
              <Route exact path='/draft-products'  element={<DraftProducts/>}/>
              <Route exact path='/product-detail/:id'  element={<ProductDetail/>}/>
              {/* modals route */}
              <Route exact path='/models'  element={<Models/>}/>
              <Route exact path='/create-model'  element={<CreateModel/>}/>
              <Route exact path='/model-detail/:id'  element={<ModelDetail/>}/>
              <Route exact path='/update-model/:id'  element={<UpdateModel/>}/>
                {/* Inventories routes */}
              <Route exact path='/add-inventory/:id'  element={<CreateInventory/>}/>
              <Route exact path='/edit-inventory/:id'  element={<UpdateInventory/>}/>
              <Route exact path='/inventory-detail/:id'  element={<InventoryDetail/>}/>
              
              <Route exact path='/inventories'  element={<Inventories/>}/>

              {/* Add products to list routes */}
              <Route exact path='/add-product-to-list'  element={<AddProuctToList/>}/>
              <Route exact path='/update-product-to-list/:id'  element={<UpdateProductToList/>}/>
              <Route exact path='/products-list'  element={<ProductsList/>}/>
              <Route exact path='/draft-products-list'  element={<DraftProductsList/>}/>
              <Route exact path='/products-list-detail/:id'  element={<ProductListDetail/>}/>
              <Route exact path='/withholding-texes'  element={<WithholdingTaxes/>}/>
              <Route exact path='/chargeable-sales-taxes'  element={<ChargeAbleSalesTax/>}/>
              <Route exact path='/vendor-tax-purchase'  element={<SalesTaxOnPurchase /> } />
              {/* <Route exact path='/guest/confirm-reservation/:token' element={<ConfirmReservation />} /> */}
              
              {/* Vendore route */}
              <Route exact path='/vendors'  element={<Vendors/>}/>
              <Route exact path='/create-vendor'  element={<CreateVendor/>}/>
              <Route exact path='/vendor-detail/:id'  element={<VendorDetail/>}/>
              <Route exact path='/update-vendor/:id'  element={<UpdateVendor/>}/>
                   {/* Vendore route */}
                   <Route exact path='/customers'  element={<Customers/>}/>
              <Route exact path='/create-customer'  element={<CreateCustomer/>}/>
              <Route exact path='/customer-detail/:id'  element={<CustomerDetail/>}/>
              <Route exact path='/update-customer/:id'  element={<UpdateCustomer/>}/>
            {/* Enquiries */}
            <Route exact path='/enquiries'  element={<Enquiries/>}/>
            <Route exact path='/draft-enquiries'  element={<DraftEnquiries/>}/>
            <Route exact path='/create-enquiry'  element={<CreateEnquiry/>}/>
            <Route exact path='/enquiry-detail/:id'  element={<EnquiryDetail/>}/>
            <Route exact path='/update-enquiry/:id'  element={<UpdateEnquiry/>}/>
              {/* Admin routes */}
              <Route exact path='/admin'  element={<AdminDashboard/>}/>
              <Route exact path='/roles'  element={<Roles/>}/>
              <Route exact path='/create-role'  element={<AddRole/>}/>
              <Route exact path='/update-role/:id'  element={<UpdateRole/>}/>
              <Route exact path='/role-detail/:id'  element={<RoleDetail/>}/>

              <Route exact path='/permissions'  element={<Permissions/>}/>
              <Route exact path='/create-permission'  element={<AddPermission/>}/>
              <Route exact path='/update-permission/:id'  element={<UpdatePermission />}/>
              <Route exact path='/permission-detail/:id'  element={<PermissionDetail/>}/>
              
            
              <Route path="*" element={<NotFound/>}/>
            </Routes>        
        </Router>
    
    </div>
  );
}

export default App;
