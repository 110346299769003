import React from 'react'
import { Link } from 'react-router-dom'

const TaxesBar = () => {
  return (
    <div className='product-bar'>
        
         <Link className='button' to="/withholding-texes">Withholding Taxes</Link>
         <Link className='button' to="/chargeable-sales-taxes">Chargeable Sales Taxes</Link>
         
         <Link className='button' to="/vendor-tax-purchase">Sales Tax On Purchase (Vednor)</Link>
        
        
        
    </div>
  )
}

export default TaxesBar