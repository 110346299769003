import React, { useEffect, useState } from "react";
import { Services } from "../../api/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate,Link,useParams } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import User from "../../images/user.png";
import Sidebar from "../Sidebar";

const CompnyDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    getCompnyDetailFunction(id);
  }, []);
  const getCompnyDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getCompnanyDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setCompany(result?.data?.data?.company);
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/compnies' className="back">
        <IoReturnDownBack  /> Go to companies
    </Link>
      <ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
         
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
              {company?.users?.length > 0 && (
                <h2 className="title-main">Users in company</h2>
              )}
              <div className="row">
                {company?.users?.map((user) => (
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="user-wrapper pointer" onClick={()=> navigate(`/user-detail/${user.id}`)}>
                      {user?.photo ? (
                        <img src={`https://account.himalayatool.com/storage/uploads/${user?.photo}`} />
                      ) : (
                        <img src={User} />
                      )}
                    
                      <div className="data">
                        {user?.name && <p className="name"> <i class="fa-solid fa-user-tie"></i> {user?.name}</p>}
                        {user?.email && <p className="name"><i class="fa-regular fa-envelope"></i> {user?.email}</p>}
                        {user?.mobile_number && <p className="name"><i class="fa-solid fa-mobile-retro"></i> {user?.mobile_number}</p>}
                        
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <h2 className="title-main">Company details</h2>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Company name</p>
                    <p className="value">{company?.company_name}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Business line</p>
                     <div className="value" dangerouslySetInnerHTML={{
                                __html:company?.business_line,
                              }}>
                                
                            </div> 
                   
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Shop address</p>
                    <p className="value">{company?.shop_address}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Mobile number</p>
                    <p className="value">{company?.mobile_number}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Office contact</p>
                    <p className="value">{company?.office_phone}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Office phone</p>
                    <p className="value">{company?.emergency_contact}</p>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Fax</p>
                    <p className="value">{company?.fax_number}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Business Starting date</p>
                    <p className="value">{company?.business_starting_date} </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Head office address</p>
                    <p className="value">{company?.head_office_address}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Business type</p>
                    <p className="value">{company?.business_type}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">NTR number</p>
                    <p className="value">
                      {company?.national_tax_registration}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">STR number</p>
                    <p className="value">{company?.sales_tax_registration}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Accounting year start</p>
                    <p className="value">{company?.accounting_year_start}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Accounting year end</p>
                    <p className="value">{company?.accounting_year_end}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompnyDetail;
