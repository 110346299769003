import React,{useState,useEffect} from 'react'
import TaxesBar from './TaxesBar'
import { Link } from 'react-router-dom'
import { IoReturnDownBack } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { Services } from '../../api/Services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChargeAbleSalesTax = () => {
    const [loading,setIsLoading]=useState(false)
    const [activeTab,setActiveTab]=useState('register')
    const [ratioRegister,setRatioRegister]=useState('')
    const [applicableDateRegister,setApplicableDateRegister]=useState('')
    const [registerRead,setRegisterRead]=useState(true)

    const [ratioNTN,setRatioNTN]=useState('')
    const [applicableDateNTN,setApplicableDateNTN]=useState('')
    const [NTNRead,setNTNRead]=useState(true)


    const registerTaxDetailApi = async ()=> {
     
      
        
        setIsLoading(true)
   

       
      
        const result = await Services.getChargableRegisterDetail()
        console.log(result)
        if (result?.status === 200) {
       setRatioRegister(result?.data?.data?.chargeable_sales_taxes_ratios[0]?.tax_ratio)
       setApplicableDateRegister(result?.data?.data?.chargeable_sales_taxes_ratios[0]?.applied_from)
       console.log(result)
       
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    
}

useEffect(()=>{
    registerTaxDetailApi()
},[])
 //api call to create income tax

 const registerTaxCreate = async ()=> {
    if(ratioRegister==""){
        toast.error("please enter ration", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        setIsLoading(true)
        const formData = new FormData();
        if(ratioRegister){
            formData.append("tax_ratio",ratioRegister)
        }
        if(ratioRegister){
            formData.append("type",'tax_for_register_customers')
        }
        if(applicableDateRegister){
            formData.append("applied_from",applicableDateRegister)
        }

       
      
        const result = await Services.updateChargableRegisterTax(formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success(result?.data?.message, {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        setRatioRegister('');
        setApplicableDateRegister('')
        registerTaxDetailApi()
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}


//sales api calls
const NTNTaxDetailApi = async ()=> {
 
  
    
    setIsLoading(true)


   
  
    const result = await Services.getChargableNTNDetail()
    console.log(result)
    if (result?.status === 200) {
   setRatioNTN(result?.data?.data?.chargeable_sales_taxes_ratios[0]?.tax_ratio)
   setApplicableDateNTN(result?.data?.data?.chargeable_sales_taxes_ratios[0]?.applied_from)
   console.log(result)
   
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);

}

useEffect(()=>{
NTNTaxDetailApi()
},[])
//api call to create sales tax

const NTNTaxCreate = async ()=> {
if(ratioNTN==""){
    toast.error("please enter ratio", {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
}

else {
    setIsLoading(true)
    const formData = new FormData();
    if(ratioNTN){
        formData.append("tax_ratio",ratioNTN)
    }
    if(ratioNTN){
        formData.append("type",'for_register_national_tax_number')
    }
    if(applicableDateNTN){
        formData.append("applied_from",applicableDateNTN)
    }

   
  
    const result = await Services.updateChargableNTNTax(formData)
    console.log(result)
    if (result?.status === 200) {
   
    toast.success(result?.data?.message, {
        autoClose: 1000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    setRatioNTN('');
    setApplicableDateNTN('')
    NTNTaxDetailApi()
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);
}
}





    
  return (
    <div className='create-company dark-theme py-3'>
    <TaxesBar />
    <Link to='/dashboard' className="back">
    <IoReturnDownBack  /> Go to dashboard
</Link>
<ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="taxes-wrapper">
    <div className="tabs">
        <div className="tab">
            <button className={`button ${activeTab == 'register' ? 'active' : ''}`} onClick={()=>setActiveTab('register')}>
                Tax For Register Customers
            </button>
        </div>
        <div className="tab">
        <button className={`button ${activeTab == 'ntn' ? 'active' : ''}`} onClick={()=>setActiveTab('ntn')}>
               For Register National Tax Number
            </button>
        </div>
    </div>

    {activeTab == 'register' &&     <div className="form-wrapper-tax form-wrapper ">
         <h2 className="title-small text-center">Tax For Register Customers</h2>
         <div className="row">
            <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Ratio
                    </span>
                    <span className="edit" onClick={()=>setRegisterRead((pre)=>!pre)}>
                    <CiEdit /> <span>Edit</span>
                    </span>
                </div>
                <input type="text" readOnly={registerRead} className='input' value={ratioRegister} placeholder='Tax ratio' onChange={(e)=>setRatioRegister(e.target.value)}  />
                </div>
            </div>
           <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Applicable Date
                    </span>
                    
                </div>
                <input type="date" readOnly={registerRead} className='input' value={applicableDateRegister} placeholder='Tax ratio' onChange={(e)=>setApplicableDateRegister(e.target.value)}  />
                </div>
            </div>
            
            </div>
            <div className="d-flex justify-content-center my-4">
                 <button className="button" onClick={registerTaxCreate}>Save Now</button>
            </div>
         
         </div>}

         {/* ====================== Sales tax goes here ================== */}

         {activeTab == 'ntn' &&     <div className="form-wrapper-tax form-wrapper ">
         <h2 className="title-small text-center">For Register National Tax Number</h2>
         <div className="row">
            <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Ratio
                    </span>
                    <span className="edit" onClick={()=>setNTNRead((pre)=>!pre)}>
                    <CiEdit /> <span>Edit</span>
                    </span>
                </div>
                <input type="text" readOnly={NTNRead} className='input' value={ratioNTN} placeholder='Tax ratio' onChange={(e)=>setRatioNTN(e.target.value)}  />
                </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Applicable Date
                    </span>
                    
                </div>
                <input type="date" readOnly={NTNRead} className='input' value={applicableDateNTN} placeholder='Tax ratio' onChange={(e)=>setApplicableDateNTN(e.target.value)}  />
                </div>
            </div>
            </div>
            <div className="d-flex justify-content-center my-4">
                <button className="button" onClick={NTNTaxCreate}>Save Now</button>
            </div>
         
         </div>}
</div>
</div>
  )
}

export default ChargeAbleSalesTax