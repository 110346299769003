import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import userAvatar from '../../images/user.png'
import { IoReturnDownBack } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import ProductBar from '../ProductBar';
const selectedCompany = localStorage.getItem("selected-company")
const Products = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [products,setProducts]=useState([])
   
const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[])
useEffect(()=>{
    getProductsList()
},[])
    const getProductsList = async ()=> {
        
            setIsLoading(true);
            const result = await Services.getProducts()
            console.log(result)
            if (result?.status === 200) {
            
           setProducts(result?.data?.data?.products)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    //delete company api
    const deleteProductFunction = async (id) => {
        const isDelete = window.prompt('Do you want to delet Product from list?');

        if (isDelete !== null) {
            setIsLoading(true);
            const result = await Services.deleteProduct(id);
            
            if (result?.status === 200) {
                toast.success("Product deleted from list", {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
                  getProductsList()
            } else {
              toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setIsLoading(false);
        } 
        
      };

  return (
    <div className='create-company dark-theme py-3'>
        <Link to='/dashboard' className="back">
            <IoReturnDownBack  /> Go to dashboard 
        </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="container-fluid">
    <div className="row">
        
        <div className="col-12 col-md-12">
     <div className="container pt-4">
        <div className="compnies-list">
        <ProductBar />
        <div className="table-responsive">
            <table className='table'>
                <thead>
                <th>Id</th>
                    <th>Name</th>
                    <th>Unit of measure</th>
               
                    <th>Description</th>
                   
                   
                    <th>Action</th>
                </thead>
                <tbody>
                {products
  ?.filter(product => product?.is_publish == '1')
  .map(product => (
    <tr key={product?.id}>
      <td>{product?.id}</td>
      <td>{product?.name}</td>
      <td>{product?.unit_of_measure}</td>
      <td>

      <div className="value" dangerouslySetInnerHTML={{
                                __html:product?.description}}></div>
                     
      </td>
      <td>
        <div className="actions">
          <span onClick={() => navigate(`/update-product/${product?.id}`)}>
            <CiEdit />
          </span>
          <span onClick={() => navigate(`/product-detail/${product?.id}`)}>
            <GrView />
          </span>
          <span onClick={() => deleteProductFunction(product?.id)}>
            <MdDeleteOutline />
          </span>
        </div>
      </td>
    </tr>
  ))}

                </tbody>
            </table>
        </div>
        </div>
        {products?.length === 0 && <h4 className='text-center title_medium'>No record found</h4>}
        
     </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default Products