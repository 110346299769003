import React from 'react'
import { useEffect,useState ,useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { Services } from '../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from './authcontext/AuthContext';

const Login = () => {
    const navigate = useNavigate()
    const {updateUser}=useContext(AuthContext)
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    const [loading,setIsLoading]=useState(false)

    const LoginFunction = async ()=> {
        if(email==""){
            toast.error("please enter email", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
       else if(password==''){
        toast.error("please enter password", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        else {
            const formData = new FormData();
            if(email){
                formData.append("email",email)
            }
            if(password){
                formData.append('password',password)
            }
            setIsLoading(true);
            const result = await Services.login(formData)
            console.log(result)
            if (result?.status === 200) {
            localStorage.setItem('token',result?.data?.data?.authorization?.token)
            toast.success(result?.data?.data?.message, {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              updateUser(result?.data?.data?.user)
         navigate('/select-company')

         
             
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    }
  return (
   <div className="login-wrapper">
    <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
    <div className="login-form-wrapper form-wrapper">
        <h1 className="title text-center">
            Login
        </h1>
        <div className="input-wrapper">
            <input type='email' className='input' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Enter your email" />
        </div>
        <div className="input-wrapper">
            <input type='password' className='input'  value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Enter your password" />
        </div>
        <div className="text-center">
            <button className='button' onClick={()=>LoginFunction()}>Login</button>
        </div>
    </div>
   </div>
  )
}

export default Login