import axios from 'axios';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = 'https://account.himalayatool.com/api'
// axios.defaults.headers.common['Authorization'] = 'Bearer'+AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';





//Roles api start here
//add role

async function addRole(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: '/roles/store',
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//update user
async function updateRole(id,data){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'post',
       url: `/roles/update/${id}`,
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : data
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}
// get users list

async function getRoles(){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'get',
       url: '/roles',
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : ''
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}


//get user detail

async function getRoleDetail(id){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'get',
       url: `/roles/${id}`,
       headers:{
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        // "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : ''
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}
// delete user

async function deleteRole(id){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'delete',
       url: `/roles/${id}`,
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : ''
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}








//Permissions api start here
//add role

async function addPermission(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: '/permissions/store',
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//update user
async function updatePermission(id,data){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'post',
       url: `/permissions/update/${id}`,
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : data
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}
// get users list

async function getPermissions(){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'get',
       url: '/permissions',
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : ''
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}


//get user detail

async function getPermissionDetail(id){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'get',
       url: `/permissions/${id}`,
       headers:{
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        // "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : ''
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}
// delete user

async function deletePermission(id){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'delete',
       url: `/permissions/${id}`,
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : ''
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}


//update password
async function updatePassword(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: `/password/update`,
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }



export const AuthService= { addRole,updateRole,getRoles,deleteRole,getRoleDetail,addPermission,updatePermission,getPermissionDetail,getPermissions,deletePermission,updatePassword}